import React from 'react'
import { Form } from 'react-final-form'

import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Button } from '../fields/Button'
import SaveButton from '../fields/SaveButton'
import TextField from '../fields/TextField'
import * as validations from '../fields/Validations'
import HeadingTag from '../ui/HeadingTag'

const DocumentNumberForm = ({
  title,
  titleClassName,
  subtitle,
  subtitleClassName,
  textLabel = 'Número de DNI',
  handleSubmitForm,
  handleCancelButton
}) => (
  <section className="max-w-lg mx-auto border border-blue-200 p-6 md:p-9 shadow-auth-register rounded">
    <HeadingTag title={title} className={clsx('font-bold text-2xl', titleClassName)} />
    {subtitle && <p className={subtitleClassName}>{subtitle}</p>}
    <Form
      onSubmit={handleSubmitForm}
      subscription={{ pristine: true, submitting: true }}
      render={({ handleSubmit, submitError, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <TextField
            name="document_number"
            label={textLabel}
            size="full"
            margin="normal"
            labelClassName="mb-2"
            validate={validations.required()}
          />
          {submitError && <div className="text-red-500 font-bold text-center pb-6">{submitError}</div>}
          <SaveButton label="Continuar" size="full" className="!mb-4" disabled={pristine || submitting} />
          <Button size="full" variant="text" onClick={handleCancelButton}>
            Regresar
          </Button>
        </form>
      )}
    />
  </section>
)

DocumentNumberForm.propTypes = {
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  subtitle: PropTypes.string,
  subtitleClassName: PropTypes.string,
  textLabel: PropTypes.string,
  handleSubmitForm: PropTypes.func,
  handleCancelButton: PropTypes.func
}

export default DocumentNumberForm
