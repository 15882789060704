import React from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'

import FilterListMobileIcon from '../../assets/images/filter_list_mobile_icon.svg'
import LineIcon from '../../assets/images/line_icon.svg'
import DateField from '../fields/DateField'
import SearchField from '../fields/SearchField'
import SelectField from '../fields/SelectField'
import * as validations from '../fields/Validations'

const messagesStates = [
  { value: '', label: 'Todos' },
  { value: 'new', label: 'Enviado' },
  { value: 'read', label: 'Leído por el destinatario' }
]

const orderOptions = [
  { value: 'sent_in desc', label: 'Más recientes primero' },
  { value: 'sent_in asc', label: 'Más antiguos primero' }
]

const MessagesFilters = ({ messageType }) => (
  <div className="border-b border-blue-200">
    <section className="bg-gray-80 p-5 md:p-6 mt-5 md:mt-0 rounded">
      <article className="text-blue-700 font-bold pb-5 hidden md:flex">
        <img src={FilterListMobileIcon} alt="filter-icon" className="mr-3" />
        Filtros de búsqueda
      </article>
      <div className="md:flex">
        {messageType === 'notification' && (
          <SelectField
            containerClassName="md:w-2/5 mb-5 md:mb-0"
            name="state"
            label="Estado"
            defaultOption=""
            orientation="horizontal"
            options={messagesStates}
          />
        )}
        <article className={clsx('md:flex items-center', { 'justify-end md:w-3/5': messageType === 'notification' })}>
          <legend className="text-base font-bold text-gray-800 pr-4 mb-2 md:mb-0">Fecha de envío</legend>
          <DateField
            name="sent_in_from"
            containerClassName="rounded !mb-6 md:!mb-0"
            placeholder="Desde"
            margin="none"
            size="md"
            validate={validations.isDate()}
          />
          <img src={LineIcon} alt="line-icon" className="mx-3 hidden md:block" />
          <DateField
            name="sent_in_to"
            containerClassName="rounded"
            placeholder="Hasta"
            margin="none"
            size="md"
            validate={validations.isDate()}
          />
        </article>
      </div>
    </section>
    <section className="md:flex justify-between mt-5 md:mt-6">
      <SearchField name="search" placeholder="Buscar por palabra clave" />
      <SelectField
        name="sort"
        label="Ordenar por:"
        orientation="horizontal"
        options={orderOptions}
        parentClassName="md:w-90"
        containerClassName=""
      />
    </section>
  </div>
)

MessagesFilters.propTypes = {
  messageType: PropTypes.string
}

export default MessagesFilters
