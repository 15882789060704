import React from 'react'

import PropTypes from 'prop-types'

import AddIcon from '../../assets/images/add_icon.svg'
import PlusIcon from '../../assets/images/plus_icon.svg'
import { Button } from '../fields/Button'

const MessageCreateOptions = ({ onClickButton }) => (
  <article className="md:w-1/5 mt-6 md:mt-0">
    <Button
      size="full"
      className="!mb-4"
      startIcon={<img src={PlusIcon} />}
      onClick={() => onClickButton('notification')}
    >
      Enviar notificación
    </Button>
    <Button size="full" variant="text" startIcon={<img src={AddIcon} />} onClick={() => onClickButton('communication')}>
      Enviar comunicación
    </Button>
  </article>
)

MessageCreateOptions.propTypes = {
  onClickButton: PropTypes.func
}

export default MessageCreateOptions
