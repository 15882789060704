import { ID_PERU_BASE_URL, ROOT_URL, SUN_KEY_BASE_URL } from '../constants/globals'

const externalUrl = {
  'SUNKEYREG': `${SUN_KEY_BASE_URL}&state=reg&redirect_uri=${ROOT_URL}/validar-identidad`,
  'SUNKEYREC': `${SUN_KEY_BASE_URL}&state=rec&redirect_uri=${ROOT_URL}/recuperar-contrasena`,
  'IDPERUREG': `${ID_PERU_BASE_URL}&state=reg&redirect_uri=${ROOT_URL}/callback`,
  'IDPERULOG': `${ID_PERU_BASE_URL}&state=log&redirect_uri=${ROOT_URL}/callback`,
  'IDPERUREC': `${ID_PERU_BASE_URL}&state=rec&redirect_uri=${ROOT_URL}/callback`
}

const redirectTo = key => (location = externalUrl[key])

export { redirectTo }
