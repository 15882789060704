import React from 'react'
import { Link, useParams } from 'react-router-dom'

import { IconButton } from '@material-ui/core'

import EditIcon from '../../../assets/images/edit_icon.svg'
import { Container } from '../../../components/Container'
import Breadcrumb from '../../../components/ui/Breadcrumb'
import HeadingTag from '../../../components/ui/HeadingTag'
import { convertDataToBreadcrumbRoute } from '../../../helpers/helpers'
import { useGobUserData } from '../../../hooks/useGobUsers'

const gobUserFields = {
  email: 'Correo electrónico',
  document_type_code: 'Tipo de documento',
  document_number: 'Número de documento',
  area_name: 'Área interna',
  profile_type: 'Tipo de perfil',
  access_modules: 'Módulos a los que tiene acceso',
  state_description: 'Estado'
}

export const UserDetail = () => {
  const { id } = useParams()
  const { data: gob_user } = useGobUserData(id)
  const breadcrumbData = convertDataToBreadcrumbRoute('Detalle de usuario')

  if (!gob_user) return

  return (
    <>
      <Breadcrumb routesKey="users" data={breadcrumbData} />
      <Container className="px-20 pt-14 pb-18">
        <section className="mb-9 flex items-end">
          <HeadingTag title={gob_user.name} className="font-bold text-4xl" />
          <IconButton
            component={Link}
            to={`/admin/gob_users/edit/${id}`}
            className="!p-0 !ml-12 hover:!bg-transparent"
            disableRipple
          >
            <img src={EditIcon} alt="edit-icon" />
            <span className="text-base font-medium text-blue-700 underline pl-2">Editar</span>
          </IconButton>
        </section>
        <section className="p-9 bg-gray-40 max-w-130 rounded">
          <article className="grid grid-cols-1 gap-3.5 pb-6">
            {Object.entries(gobUserFields).map(
              ([key, value], index) =>
                gob_user[key] && (
                  <p key={index}>
                    <b>{value}:</b> {gob_user[key]}
                  </p>
                )
            )}
          </article>
          <Link to="/admin/areas-y-usuarios" className="font-medium underline text-blue-700">
            Regresar
          </Link>
        </section>
      </Container>
    </>
  )
}
