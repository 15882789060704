import * as validations from '../components/fields/Validations'

export const MAX_LENGTH_DOCUMENT_DESCRIPTION = 152
export const MAX_LENGTH_MESSAGE_SUBJECT_TABLE = 20
export const MAX_LENGTH_MESSAGE_BREADCRUMB = 40
export const MAX_LENGTH_MESSAGE_SUBJECT_INPUT = 150
export const INSTITUTION_GOBPE_ID = 63
export const AREAS_USERS = 'areas_users'
export const SERVICE_OK = 200
export const UNAUTHORIZED = 401
export const UNPROCESSABLE_ENTITY = 422
export const ID_GOB_PE_BASE_URL = import.meta.env.VITE_ID_GOB_PE_BASE_URL
export const ID_GOB_PE_CLIENT_ID = import.meta.env.VITE_ID_GOB_PE_CLIENT
export const ID_GOB_PE_RESPONSE_TYPE = import.meta.env.VITE_ID_GOB_PE_RESPONSE_TYPE
export const ID_GOB_PE_ACR_VALUE = import.meta.env.VITE_ID_GOB_PE_ACR_TYPE
export const ID_GOB_PE_SCOPE = import.meta.env.VITE_ID_GOB_PE_SCOPE
export const ID_GOB_PE_PROFILE_URI = import.meta.env.VITE_ID_GOB_PE_PROFILE_URI
export const ID_GOB_PE_STATE = Date.now() + '' + Math.random()
export const APP_ENV = import.meta.env.MODE
export const LINK_INFORMATION_CUE = 'https://www.gob.pe/casilla-unica-electronica'
export const LINK_INFORMATION_CCI = 'https://www.gob.pe/26621-plataforma-digital-carpeta-ciudadana'
export const ROOT_URL = import.meta.env.VITE_ROOT_URL
export const SUN_KEY_BASE_URL = import.meta.env.VITE_SOL_URL
export const ID_PERU_BASE_URL = import.meta.env.VITE_ID_PERU_URL

export const ORDER_OPTIONS = [
  { value: 'created_at desc', label: 'Más recientes primero' },
  { value: 'created_at asc', label: 'Más antiguos primero' }
]

export const PASSWORD_RULES = [
  { validate: validations.minLength(8), text: '8 caracteres o más' },
  { validate: validations.minOneLetter(), text: 'Al menos una letra' },
  { validate: validations.minOneNumber(), text: 'Al menos un número' }
]

export const CONTENT_TYPES = ['application/json', 'application/x-www-form-urlencoded']

export const OAUTH_GRANT_TYPES = ['client_credentials', 'password']

export const LABEL_TYPES = {
  success: 'bg-green-800',
  luxury: 'bg-indigo-500',
  disabled: 'bg-neutral-300',
  danger: 'bg-red-750',
  nobility: 'bg-purple-200',
  dusty: 'bg-gray-525'
}

export const MESSAGE_STATUS = {
  read: {
    exportType: 'receipt',
    documentType: 'Acuse de recibo'
  },
  unread: {
    exportType: 'sender',
    documentType: 'Constancia de envío'
  }
}

export const DOCUMENT_TYPES = [{ label: 'DNI', value: 'dni' }]

export const CONFIRMATION_TYPES = [
  { label: 'Sí', value: 'yes' },
  { label: 'No', value: 'no' },
  { label: 'No estoy seguro(a)', value: 'not_sure' }
]
