import React from 'react'
import { useNavigate } from 'react-router-dom'

import PropTypes from 'prop-types'

import EmailSendNormalIcon from '../../assets/images/email_send_normal_icon.svg'
import EmailSendPressedIcon from '../../assets/images/email_send_pressed_icon.svg'
import PersonalQuestionsIcon from '../../assets/images/personal_questions_icon.svg'
import CellPhoneSendIcon from '../../assets/images/phone_send_icon.svg'
import UserCheckIcon from '../../assets/images/user_check_icon.svg'
import { Button } from '../fields/Button'
import HeadingTag from '../ui/HeadingTag'
import IconChangeButton from '../ui/IconChangeButton'

const PasswordRecoveryOptions = ({
  handleEmailOptionButton,
  handleMovilOptionButton,
  handleSolOptionButton,
  handlePideOptionButton,
  handleSelfieOptionButton
}) => {
  const navigate = useNavigate()

  return (
    <section className="max-w-lg mx-auto md:text-center">
      <HeadingTag title="Recuperar contraseña" className="text-3xl font-bold" />
      <p className="pt-3.5 md:pt-4">Selecciona como quieres recuperar tu contraseña</p>
      <div className="grid grid-cols-1 gap-4 pt-6 pb-7.5 md:pt-7.5">
        <IconChangeButton
          label="Recibe un código de verificación a tu correo electrónico registrado"
          handleClick={handleEmailOptionButton}
          normalIcon={EmailSendNormalIcon}
          hoverIcon={EmailSendNormalIcon}
          pressedIcon={EmailSendPressedIcon}
        />
        <IconChangeButton
          label="Recibe un código de verificación a tu número de celular registrado"
          handleClick={handleMovilOptionButton}
          normalIcon={CellPhoneSendIcon}
          hoverIcon={CellPhoneSendIcon}
          pressedIcon={CellPhoneSendIcon}
        />
        <IconChangeButton
          label="Valida tu identidad respondiendo preguntas personales"
          handleClick={handlePideOptionButton}
          normalIcon={PersonalQuestionsIcon}
          hoverIcon={PersonalQuestionsIcon}
          pressedIcon={PersonalQuestionsIcon}
        />
        <IconChangeButton
          label="Valida tu identidad mediante Clave SOL"
          handleClick={handleSolOptionButton}
          normalIcon={UserCheckIcon}
          hoverIcon={UserCheckIcon}
          pressedIcon={UserCheckIcon}
        />
        <IconChangeButton
          label="Valida tu identidad mediante autenticación facial"
          handleClick={handleSelfieOptionButton}
          normalIcon={CellPhoneSendIcon}
          hoverIcon={CellPhoneSendIcon}
          pressedIcon={CellPhoneSendIcon}
        />
      </div>
      <Button size="full" variant="secondary" onClick={() => navigate('/', { replace: true })}>
        Cancelar
      </Button>
    </section>
  )
}

PasswordRecoveryOptions.propTypes = {
  handleEmailOptionButton: PropTypes.func,
  handleSolOptionButton: PropTypes.func,
  handlePideOptionButton: PropTypes.func,
  handleMovilOptionButton: PropTypes.func,
  handleSelfieOptionButton: PropTypes.func
}

export default PasswordRecoveryOptions
