import { useEffect, useState } from 'react'

export const useLocationSearch = () => {
  const [search, setSearch] = useState({ isSearching: true })

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams) {
      setSearch({ ...Object.fromEntries(searchParams), isSearching: false })
    }
  }, [location.search])

  return search
}
