import React, { Fragment } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { FORM_ERROR } from 'final-form'

import { Container } from '../../../components/Container'
import { UsersForm } from '../../../components/forms/admin/UsersForm'
import Breadcrumb from '../../../components/ui/Breadcrumb'
import HeadingTag from '../../../components/ui/HeadingTag'
import { allowedModules } from '../../../configs/users'
import { convertDataToBreadcrumbRoute, filterObject, urlSearchParamsData } from '../../../helpers/helpers'
import { useGobUserData, useUpdateGobUser } from '../../../hooks/useGobUsers'

export const UserEdit = () => {
  const { institution_gobpe_id } = urlSearchParamsData()
  const { id } = useParams()
  const { data: gob_user } = useGobUserData(id)
  const { mutate: patchGobUser } = useUpdateGobUser()
  const navigate = useNavigate()

  const breadcrumbData = convertDataToBreadcrumbRoute('Editar usuario')

  const onSubmit = async values => {
    try {
      const gobUserData = filterObject(values, ['area_id', 'role', 'allowed_modules', 'active'])

      const data = {
        ...gobUserData,
        allowed_modules: values.role !== 'collaborator' ? [] : values.allowed_modules ? allowedModules : [],
        ...(institution_gobpe_id && { institution_gobpe_id }),
        id
      }

      patchGobUser(data)

      navigate(
        institution_gobpe_id
          ? `/admin/areas-y-usuarios?institution_gobpe_id=${institution_gobpe_id}`
          : '/admin/areas-y-usuarios'
      )
    } catch (error) {
      return { [FORM_ERROR]: 'Ocurrió un error al editar el usuario.' }
    }
  }

  if (!gob_user) return

  return (
    <Fragment>
      <Breadcrumb routesKey="users" data={breadcrumbData} />
      <Container className="px-20 pt-14 pb-18">
        <HeadingTag title="Editar usuario" className="mb-7.5 font-bold text-4xl" />
        <UsersForm
          initialValues={{
            ...gob_user,
            document_type_code: gob_user.document_type_code?.toLowerCase(),
            allowed_modules: gob_user.allowed_modules?.length ? allowedModules[0].value : ''
          }}
          onSubmit={onSubmit}
        />
      </Container>
    </Fragment>
  )
}
