import React from 'react'

import PropTypes from 'prop-types'

import { messageContentByStatus, messageStatus, tagConfig } from '../../configs/messages'
import StatusTrack from '../ui/StateTrack'
import Tag from '../ui/Tag'

const NotificationMessageContent = ({
  id,
  sent_in,
  is_read,
  readed_at,
  state,
  state_type,
  gob_user_name,
  area_name
}) => (
  <>
    <article className="flex items-center gap-5 pb-5 md:pb-7.5">
      <p className="text-xl md:text-2xl font-bold">Estado:</p>
      <Tag {...tagConfig[state_type]} className="!text-base h-7" />
    </article>
    <StatusTrack
      steps={messageStatus}
      activeStep={state}
      stepContent={messageContentByStatus(id, sent_in, is_read, readed_at)}
    />
    <article className="pt-7.5 mt-6 md:mt-7.5 border-gray-50 border-t-2">
      <p className="font-bold">Enviado por:</p>
      {gob_user_name} - {area_name}
    </article>
  </>
)

const CommunicationMessageContent = ({ sendBy, sendDate }) => (
  <div className="grid grid-cols-1 gap-4">
    <article>
      <p className="font-bold">Enviado por:</p>
      {sendBy}
    </article>
    <article>
      <p className="font-bold">Fecha y hora de envío:</p>
      {sendDate}
    </article>
  </div>
)

const PendingMessageContent = ({ scheduledSendDate, sendBy }) => (
  <>
    <article className="flex items-center gap-5 pb-6">
      <p className="text-xl md:text-2xl font-bold">Estado:</p>
      <Tag {...tagConfig['pending']} className="!text-base h-7" />
    </article>
    <p>
      Esta notificación se enviará el siguiente día hábil, al inicio del horario de atención definido por la entidad.
    </p>
    <article className="pt-4">
      <p className="font-bold">Fecha programada de envío:</p>
      {scheduledSendDate}
    </article>
    <article className="pt-7.5 mt-6 md:mt-7.5 border-gray-50 border-t-2">
      <p className="font-bold">Enviada por:</p>
      {sendBy}
    </article>
  </>
)

const getAsideContent = message => ({
  'notification': <NotificationMessageContent {...message} state_type={message.is_read ? 'read' : 'sent'} />,
  'communication': (
    <CommunicationMessageContent
      sendBy={`${message.gob_user_name} - ${message.area_name}`}
      sendDate={message.sent_in}
    />
  )
})

const MessageContentAside = ({ message }) => (
  <aside className="p-6 md:p-10 bg-white border border-blue-200 shadow-auth-register rounded md:w-1/3">
    {message.pending ? (
      <PendingMessageContent
        scheduledSendDate={message.schedule_send_date}
        sendBy={`${message.gob_user_name} - ${message.area_name}`}
      />
    ) : (
      getAsideContent(message)[message.message_type]
    )}
  </aside>
)

export default MessageContentAside

MessageContentAside.propTypes = {
  message: PropTypes.object
}

NotificationMessageContent.propTypes = {
  id: PropTypes.string,
  sent_in: PropTypes.string,
  is_read: PropTypes.bool,
  readed_at: PropTypes.string,
  state: PropTypes.array,
  state_type: PropTypes.string,
  gob_user_name: PropTypes.string,
  area_name: PropTypes.string
}

CommunicationMessageContent.propTypes = {
  sendBy: PropTypes.string,
  sendDate: PropTypes.string
}

PendingMessageContent.propTypes = {
  scheduledSendDate: PropTypes.string,
  sendBy: PropTypes.string
}
