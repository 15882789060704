import React, { useState } from 'react'
import { useField } from 'react-final-form'

import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
  radio: {
    display: 'none'
  },
  formControl: {
    margin: 0
  },
  label: {
    fontWeight: 700
  }
})

const RadioTabs = ({ name, options = [], tabDefault, parentClassName, ...props }) => {
  const { input } = useField(name, { ...props })
  const [selected, setSelected] = useState(tabDefault)
  const classes = useStyles()

  return (
    <FormControl className={clsx('bg-blue-100', parentClassName)} component="fieldset">
      <RadioGroup
        row
        aria-label="radio-tabs"
        className="m-1"
        {...input}
        onChange={e => {
          input.onChange(e.target.value)
          props.onChange && props.onChange(e.target.value)
          setSelected(e.target.value)
        }}
      >
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            label={option.label}
            value={option.value}
            className={clsx(
              'py-2.5 px-2.25 md:px-8 rounded',
              selected === option.value && 'bg-white shadow-radio-tabs'
            )}
            classes={{ root: classes.formControl, label: classes.label }}
            control={<Radio classes={{ root: classes.radio }} />}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

RadioTabs.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  tabDefault: PropTypes.string,
  parentClassName: PropTypes.string,
  onChange: PropTypes.func
}

export default RadioTabs
