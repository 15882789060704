import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { request } from '../../api/apiCore'
import EmailSendNormalIcon from '../../assets/images/email_send_normal_icon.svg'
import EmailSendPressedIcon from '../../assets/images/email_send_pressed_icon.svg'
import CellPhoneSendIcon from '../../assets/images/phone_send_icon.svg'
import { useAuth } from '../../auth/Auth'
import { Container } from '../../components/Container'
import HeadingTag from '../../components/ui/HeadingTag'
import IconChangeButton from '../../components/ui/IconChangeButton'

export const CodeLoginPage = () => {
  const navigate = useNavigate()
  const {
    dispatch,
    user: { document_number }
  } = useAuth()

  const handleSendCode = async send_type => {
    try {
      const data = { document_type_code: 'dni', document_number, send_type }
      const response = await request({ url: '/authentication/send-code.json', data, method: 'POST' })

      dispatch({ type: 'Verification', payload: { ...response, document_number, send_type } })
      navigate('/verificar-codigo')
    } catch (error) {
      console.log('🚀 ~ handleSendCode ~ error:', error)
    }
  }

  return (
    <Container className="px-5 pt-8 md:h-screen pb-14 md:px-20 md:pt-14 md:pb-18 text-center">
      <HeadingTag title="Iniciar sesión con código" className="text-3xl font-bold" />
      <p className="pt-3.5 md:pt-4">Selecciona el dato de contacto al que enviaremos tu código de verificación</p>
      <section className="grid grid-cols-1 gap-4 pt-6 pb-7.5 md:pt-7.5 max-w-lg mx-auto">
        <IconChangeButton
          label="Recibe un código de verificación a tu número de celular registrado"
          handleClick={() => handleSendCode('sms')}
          normalIcon={CellPhoneSendIcon}
          hoverIcon={CellPhoneSendIcon}
          pressedIcon={CellPhoneSendIcon}
        />
        <IconChangeButton
          label="Recibe un código de verificación a tu correo electrónico registrado"
          handleClick={() => handleSendCode('email')}
          normalIcon={EmailSendNormalIcon}
          hoverIcon={EmailSendNormalIcon}
          pressedIcon={EmailSendPressedIcon}
        />
        <Link to="/" className="py-3.5 text-blue-700 font-bold border-2 border-blue-700 rounded">
          Cancelar
        </Link>
      </section>
    </Container>
  )
}
