import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactSVG } from 'react-svg'

import Grow from '@material-ui/core/Grow'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'

import { request } from '../../api/apiCore'
import ArrowDownIcon from '../../assets/images/arrow_down_icon.svg'
import { useAuth } from '../../auth/Auth'
import { isAdminRoute, removeItemFromLocalStorage } from '../../helpers/helpers'
import { useIdGobPeAcf } from '../../hooks/useIdGobPe'

const getFirstLetter = text => {
  if (!text) return
  return text[0].toUpperCase()
}

const getUsername = user => {
  if (isAdminRoute) return user.name
  const firstName = user.name.split(' ').length > 1 ? user.name.split(' ')[0] : user.name
  return `${firstName} ${user.father_last_name}`
}

export const UserMenu = () => {
  const { user, dispatch, admin, adminDispatch } = useAuth()
  const { logout } = useIdGobPeAcf()
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const navigate = useNavigate()

  const currentUser = user.logged && !isAdminRoute ? user : admin

  const handleToggle = () => setOpen(prevOpen => !prevOpen)

  const handleLogout = () => {
    if (user.logged && !isAdminRoute) {
      if (user.type === 'idGobPe') {
        removeItemFromLocalStorage(['userData'])
        logout()
        return
      }

      dispatch({ type: 'Logout' })
      navigate('/')
    }

    if (admin.logged) {
      request({ url: '/admin/sessions', method: 'DELETE' }).then(adminDispatch({ type: 'Logout' }))
      navigate('/admin')
    }

    handleToggle()
  }

  const handleSelectedItem = () => {
    handleToggle()
    navigate('/perfil')
  }

  if ((isAdminRoute && !admin.logged) || (!isAdminRoute && !user.logged)) return null

  return (
    <>
      <button
        className="flex text-sm items-center ml-auto"
        ref={anchorRef}
        aria-controls={open && 'menu-list-grow'}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <figure className="bg-white p-2 text-red-700 font-bold rounded">
          {getFirstLetter(currentUser.name)}
          {getFirstLetter(currentUser.father_last_name)}
        </figure>
        <p className="pl-4 text-white font-bold hidden md:block">{getUsername(currentUser)}</p>
        <ReactSVG src={ArrowDownIcon} className="ml-2 md:ml-4 text-white" />
      </button>
      <Popper open={open} anchorEl={anchorRef.current} transition placement="bottom-end" className="z-10">
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <MenuList autoFocusItem={open} id="menu-list-grow">
                {!isAdminRoute && <MenuItem onClick={handleSelectedItem}>Mi perfil</MenuItem>}
                <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}
