import React from 'react'

import PropTypes from 'prop-types'

import { Button } from './Button'

const SaveButton = ({
  label = 'Guardar',
  className = 'ml-auto',
  variant = 'primary',
  disabled = false,
  size = 'full',
  startIcon
}) => {
  return (
    <Button
      size={size}
      variant={variant}
      type="submit"
      className={`!font-bold ${className}`}
      disabled={disabled}
      startIcon={startIcon}
    >
      {label}
    </Button>
  )
}
export default SaveButton

SaveButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  startIcon: PropTypes.element
}
