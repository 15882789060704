import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import jwtDecode from 'jwt-decode'

import { request } from '../../api/apiCore'
import { useAuth } from '../../auth/Auth'
import { Container } from '../../components/Container'
import DocumentNumberForm from '../../components/forms/DocumentNumberForm'
import IdentityValidateForm from '../../components/forms/IdentityValidateForm'
import Loader from '../../components/ui/Loader'
import PopUpWindow from '../../components/ui/PopUpWindow'
import IdentityValidateOptions from '../../components/users/IdentityValidateOptions'
import { registerLevel } from '../../configs/users'
import { setItemToLocalStorage } from '../../helpers/helpers'
import { redirectTo } from '../../helpers/services'
import useShowMessage from '../../hooks/useShowMessage'
import { useSunKey } from '../../hooks/useSunKey'

export const IdentityValidatePage = () => {
  const {
    dispatch,
    user: { jwt }
  } = useAuth()
  const [showContent, setShowContent] = useState({ options: true, docForm: false, validateForm: false, loader: false })
  const { search, getSunatUserData } = useSunKey()
  const { message, setMessage } = useShowMessage()
  const navigate = useNavigate()

  useEffect(() => {
    validateSunKeyResponse()
  }, [search])

  const validateSunKeyResponse = async () => {
    if (search.isSearching) return

    if (search.code) {
      setShowContent({ ...showContent, loader: true })
      const response = await getSunatUserData(search.code, search.state)

      if (response.hasError) {
        setMessage({ title: 'Aviso', content: <span className="pt-6 pb-7.5">{response.message}</span>, show: true })
        setShowContent({ ...showContent, loader: false })
        navigate('/validar-identidad', { replace: true })
      } else {
        dispatch({ type: 'Registration', payload: { jwt: response.data.token } })
        setItemToLocalStorage(
          'userRegistryData',
          JSON.stringify({ ...response.data.user, register_level: registerLevel.CLAVE_SOL })
        )
        navigate('/informacion-usuario', { replace: true })
      }
    }
  }

  const handleSubmitDocumentNumberForm = async ({ document_number }) => {
    const verifyUser = await request({
      url: '/registration/verify-user',
      method: 'POST',
      data: { document_type_code: 'dni', document_number }
    })

    const errorMessage = verifyUser.response?.data?.error

    if (errorMessage) {
      setMessage({ title: 'Aviso', content: <span className="pt-6 pb-7.5">{errorMessage}</span>, show: true })
      return
    }

    dispatch({ type: 'Registration', payload: { jwt: verifyUser.token } })
    setShowContent({ ...showContent, docForm: false, validateForm: true })
  }

  const handleSubmitIdentityValidateForm = async values => {
    const { document_number: dni } = jwtDecode(jwt)

    try {
      const verifyPersonalData = await request({
        url: '/registration/verify-personal-data.json',
        method: 'POST',
        data: { dni, ...values }
      })

      if (verifyPersonalData.token) {
        dispatch({ type: 'Registration', payload: { jwt: verifyPersonalData.token } })
        setItemToLocalStorage(
          'userRegistryData',
          JSON.stringify({ ...verifyPersonalData.pide_data, register_level: registerLevel.PIDE })
        )
        navigate('/informacion-usuario')
      } else {
        const errorData = verifyPersonalData.response.data
        setMessage({
          title: errorData.name,
          content: <span className="pt-6 pb-7.5">{errorData.description}</span>,
          show: true
        })
        setShowContent({ ...showContent, validateForm: false, options: true })
      }
    } catch (error) {
      setMessage({
        ...message,
        title: 'Error',
        content: <span className="pt-6 pb-7.5">{error.response.data.message}</span>,
        show: true
      })
    }
  }

  return (
    <Container className="px-5 pt-8 pb-14 md:px-20 md:pt-14 md:pb-18">
      {showContent.options && !showContent.loader && (
        <IdentityValidateOptions
          handleQuestionsOptionButton={() => setShowContent({ ...showContent, options: false, docForm: true })}
          handleValidationOptionButton={() => redirectTo('SUNKEYREG')}
          handleSelfieOptionButton={() => redirectTo('IDPERUREG')}
        />
      )}
      {showContent.loader && <Loader message="Espera un momento, estamos validando los datos ingresados." />}
      {showContent.docForm && (
        <DocumentNumberForm
          title="Ingresa tu número DNI"
          titleClassName="pb-6 md:pb-7.5 md:text-center"
          handleSubmitForm={handleSubmitDocumentNumberForm}
          handleCancelButton={() => setShowContent({ ...showContent, options: true, docForm: false })}
        />
      )}
      {showContent.validateForm && (
        <IdentityValidateForm
          title="Valida tu identidad"
          subtitle="Completa tus datos personales, "
          subtitleHighlight="de acuerdo a la información registrada en tu DNI:"
          subtitleHighlightClassName="font-bold"
          handleSubmitForm={handleSubmitIdentityValidateForm}
          handleCancelButton={() => {
            setShowContent({ ...showContent, docForm: true, validateForm: false })
          }}
        />
      )}
      {message.show && (
        <PopUpWindow
          title={message.title}
          paperProps={{
            padding: 36,
            borderRadius: 4,
            maxWidth: 482,
            boxShadow: '2px 3px 23px -4px #D3E0EC'
          }}
          showCloseButton
          open={message.show}
          onClose={() => setMessage({ ...message, show: false })}
        >
          {message.content}
        </PopUpWindow>
      )}
    </Container>
  )
}
