import React from 'react'
import { useNavigate } from 'react-router-dom'

import { request } from '../../api/apiCore'
import { useAuth } from '../../auth/Auth'
import { Container } from '../../components/Container'
import IdentityValidateForm from '../../components/forms/IdentityValidateForm'
import PopUpWindow from '../../components/ui/PopUpWindow'
import useShowMessage from '../../hooks/useShowMessage'

export const PasswordRecoveryPidePage = () => {
  const { dispatch, user } = useAuth()
  const { message, setMessage } = useShowMessage()
  const navigate = useNavigate()

  const handleSubmit = async values => {
    try {
      const verifyPersonalData = await request({
        url: '/authentication/verify-personal-data',
        data: { ...values, dni: user.document_number },
        method: 'POST'
      })

      if (verifyPersonalData.token) {
        dispatch({ type: 'Recovery', payload: { token: verifyPersonalData.token } })
        navigate('/contrasena-usuario')
      } else {
        const errorData = verifyPersonalData.response.data
        setMessage({
          title: errorData.name,
          content: <span className="pt-6 pb-7.5">{errorData.description}</span>,
          show: true
        })
      }
    } catch (error) {
      setMessage({
        title: 'Error',
        content: <span className="pt-6 pb-7.5">{error.response.data.message}</span>,
        show: true
      })
    }
  }

  const handleClosePopUp = () => {
    setMessage({ ...message, show: false })
    navigate('/recuperar-contrasena', { replace: true })
  }

  return (
    <Container className="px-5 pt-8 pb-14 md:px-20 md:pt-14 md:pb-18">
      <IdentityValidateForm
        title="Recuperar contraseña"
        subtitle="Completa tus datos personales, "
        subtitleHighlight="de acuerdo a la información registrada en tu DNI:"
        cancelButtonLabel="Volver al inicio"
        handleSubmitForm={handleSubmit}
        handleCancelButton={() => navigate('/')}
      />
      {message.show && (
        <PopUpWindow
          title={message.title}
          paperProps={{
            padding: 36,
            borderRadius: 0,
            maxWidth: 482,
            boxShadow: '2px 3px 23px -4px #D3E0EC'
          }}
          showCloseButton
          open={message.show}
          onClose={handleClosePopUp}
        >
          {message.content}
        </PopUpWindow>
      )}
    </Container>
  )
}
