import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { request } from '../api/apiCore'
import { useAuth } from '../auth/Auth'
import Loader from '../components/ui/Loader'
import PopUpWindow from '../components/ui/PopUpWindow'
import { registerLevel } from '../configs/users'
import { setItemToLocalStorage } from '../helpers/helpers'
import { useLocationSearch } from '../hooks/useLocationSearch'
import useShowMessage from '../hooks/useShowMessage'

const redirectPath = { log: '/', reg: '/validar-identidad', rec: '/recuperar-contrasena' }

export const CallbackPage = () => {
  const search = useLocationSearch()
  const { message, setMessage } = useShowMessage()
  const [isLoading, setIsLoading] = useState(false)
  const {
    user: { document_number },
    dispatch
  } = useAuth()
  const navigate = useNavigate()

  const validateSearchParams = () => {
    if (search.isSearching) return
    if (search.error) {
      navigate(redirectPath[search.state], { replace: true })
      return
    }
    getUserInfo(search.code, search.state)
  }

  const getUserInfo = async (code, state) => {
    setIsLoading(true)
    const userInfo = await request({ url: '/idperu/user.json', data: { code, state, document_number }, method: 'POST' })
    const errorMessage = userInfo.response?.data?.error

    if (errorMessage) {
      setMessage({
        title: 'No pudimos validar tu identidad',
        content: <p className="pt-6 pb-7.5">{errorMessage}</p>,
        show: true
      })
      setIsLoading(false)
      return
    }

    dispatchByState(state, userInfo)
  }

  const dispatchByState = (state, user) => {
    switch (state) {
      case 'reg':
        dispatch({ type: 'Registration', payload: { jwt: user.token } })
        setItemToLocalStorage(
          'userRegistryData',
          JSON.stringify({ name: user.name, register_level: registerLevel.ID_PERU })
        )
        navigate('/informacion-usuario')
        break

      case 'log':
        dispatch({ type: 'Login', payload: { ...user.data, id: user.data.user_id, jwt: user.token, type: 'idPeru' } })
        break

      case 'rec':
        dispatch({ type: 'Recovery', payload: { ...user } })
        navigate('/contrasena-usuario')
        break
    }
  }

  const handleClosePopUp = () => {
    setMessage({ ...message, show: false })
    navigate(redirectPath[search.state], { replace: true })
  }

  useEffect(() => {
    validateSearchParams()
  }, [search])

  if (isLoading) return <Loader message="Espera un momento, estamos validando la respuesta del servicio." />

  return (
    <>
      {message.show && (
        <PopUpWindow
          title={message.title}
          paperProps={{
            padding: 36,
            borderRadius: 4,
            maxWidth: 482,
            boxShadow: '2px 3px 23px -4px #D3E0EC'
          }}
          showCloseButton
          open={message.show}
          onClose={handleClosePopUp}
        >
          {message.content}
        </PopUpWindow>
      )}
    </>
  )
}
