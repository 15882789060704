import React from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useNavigate } from 'react-router-dom'

import { FORM_ERROR } from 'final-form'

import { request } from '../../api/apiCore'
import { useAuth } from '../../auth/Auth'
import { Container } from '../../components/Container'
import UserPasswordForm from '../../components/forms/UserPasswordForm'
import FormsHeader from '../../components/ui/FormsHeader'
import ScrollToTop from '../../components/ui/ScrollToTop'
import { UserFormSteps } from '../../configs/users'
import { removeItemFromLocalStorage, setItemToLocalStorage } from '../../helpers/helpers'

const pageData = {
  reg: {
    title: 'Creación de contraseña',
    passwordLabel: 'Ingresa tu contraseña',
    submitLabel: 'Crear cuenta'
  },
  rec: {
    title: 'Recuperación de contraseña',
    passwordLabel: 'Ingresa tu nueva contraseña',
    submitLabel: 'Cambiar contraseña'
  }
}

const setAlertData = data => setItemToLocalStorage('actionAlert', JSON.stringify(data))

export const UserPasswordPage = () => {
  const { user, dispatch } = useAuth()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const navigate = useNavigate()
  const flowType = user.registry ? 'reg' : user.changePassword ? 'rec' : 'undefined'

  if (!executeRecaptcha) console.log('Execute recaptcha not yet available')

  const handleSubmit = async ({ password }) => {
    try {
      if (user.registry) {
        const { id } = await request({ url: '/users', data: { ...user, password }, method: 'POST' })

        if (id) {
          setAlertData({ text: 'Tu cuenta fue creada exitosamente.', type: 'success' })
        } else {
          setAlertData({ text: 'No se pudo crear cuenta.', type: 'warning' })
        }
        handleCancel()
      }

      if (user.changePassword) {
        const recaptcha_action = 'change_password'
        const recaptcha = await executeRecaptcha(recaptcha_action)

        if (recaptcha) {
          const data = { ...user, password, recaptcha, recaptcha_action }
          const { message } = await request({ url: '/authentication/change-password', data, method: 'POST' })

          if (message === 'Password changed') {
            setAlertData({ text: 'Se actualizó la contraseña exitosamente.', type: 'success' })
          } else {
            setAlertData({ text: 'No se pudo actualizar su contraseña.', type: 'warning' })
          }
          navigate('/')
        }
      }
    } catch (error) {
      return { [FORM_ERROR]: `Ocurrió un error: ${error.response.data.message}` }
    }
  }

  const handleCancel = () => {
    removeItemFromLocalStorage(['userRegistryData'])
    dispatch({ type: 'Logout' })
  }

  return (
    <Container className="px-5 pt-8 2xl:h-screen pb-14 md:px-20 md:pt-14 md:pb-18">
      {user.registry && (
        <FormsHeader
          title="Creando mi cuenta de carpeta ciudadana"
          steps={UserFormSteps}
          activeStep={UserFormSteps.SET_PASSWORD}
        />
      )}
      <UserPasswordForm
        title={pageData[flowType].title}
        passwordFieldLabel={pageData[flowType].passwordLabel}
        submitButtonLabel={pageData[flowType].submitLabel}
        handleSubmitForm={handleSubmit}
        handleCancelForm={handleCancel}
      />
      <ScrollToTop />
    </Container>
  )
}
