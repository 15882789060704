import React from 'react'
import { Form } from 'react-final-form'
import { Link } from 'react-router-dom'
import { ReactSVG } from 'react-svg'

import PropTypes from 'prop-types'

import EmailSendNormalIcon from '../../assets/images/email_send_base_icon.svg'
import PadlockIcon from '../../assets/images/padlock_icon.svg'
import PersonalQuestionsIcon from '../../assets/images/personal_questions_base_icon.svg'
import CellPhoneSendIcon from '../../assets/images/phone_send_base_icon.svg'
import UserCheckIcon from '../../assets/images/user_check_base_icon.svg'
import { redirectTo } from '../../helpers/services'
import SaveButton from '../fields/SaveButton'
import TextField from '../fields/TextField'
import * as validations from '../fields/Validations'
import HeadingTag from '../ui/HeadingTag'
import Timer from '../ui/Timer'

const formTexts = {
  login: {
    bottomButton: 'Regresar',
    bottomOptions: 'Otras maneras de iniciar sesión:'
  },
  registry: {
    bottomButton: 'Regresar'
  },
  recovery: {
    bottomButton: 'Volver al inicio',
    bottomOptions: 'Otras maneras de recuperar contraseña:'
  }
}

const sendTexts = {
  phone: {
    subtitle: 'Hemos enviado un código al siguiente número de celular será valido por 10 minutos',
    unsent: '¿No recibiste el mensaje de texto?',
    changeIcon: EmailSendNormalIcon,
    changeContact: 'Recibe un código a tu correo electrónico registrado'
  },
  email: {
    subtitle: 'Hemos enviado un código al siguiente correo electrónico será valido por 10 minutos',
    unsent: '¿No recibiste el correo?',
    changeIcon: CellPhoneSendIcon,
    changeContact: 'Recibe un código a tu número de celular registrado'
  }
}

const LinkOption = ({ pathname, icon, description }) => (
  <Link className="flex items-center mx-auto" to={pathname}>
    <ReactSVG src={icon} />
    <span className="ml-3 font-medium text-blue-700 underline">{description}</span>
  </Link>
)

const ButtonOption = ({ icon, description, className, textClassName, handleClick }) => (
  <button type="button" className={`flex items-center mx-auto ${className}`} onClick={handleClick}>
    {icon && <ReactSVG src={icon} />}
    <span className={`font-medium text-blue-700 underline ${textClassName}`}>{description}</span>
  </button>
)

const CodeVerifyForm = ({
  title,
  contact,
  handleSubmitForm,
  handleCodeResend,
  handleBottomButton,
  showBottomOptions = false,
  timerConfig,
  setTimerConfig,
  isPhoneSend = false,
  handleSendCodeButton,
  showCodeError = true,
  flowType
}) => {
  const sendKey = isPhoneSend ? 'phone' : 'email'

  return (
    <section className="max-w-xl p-6 mx-auto border border-blue-200 md:text-center md:p-9 shadow-auth-register rounded">
      <HeadingTag title={title} className="text-3xl font-bold" />
      <HeadingTag as="h2" title={sendTexts[sendKey].subtitle} className="pb-4 pt-3.5 md:pt-4 text-center" />
      <p className="font-bold mb-8 text-center">{contact}</p>
      <Form
        onSubmit={handleSubmitForm}
        subscription={{
          pristine: true,
          submitting: false,
          submitError: showCodeError,
          submitSucceeded: true
        }}
        render={({ handleSubmit, submitError, submitting, pristine, form, submitSucceeded }) => (
          <form onSubmit={handleSubmit} className="mt-7.5 md:mt-6">
            <TextField
              name="code"
              label="Ingresa el código"
              size="full"
              margin="dense"
              labelClassName="text-left mb-2"
              rootClassName="p-5 md:p-7.5 bg-gray-80 rounded"
              maxLength={6}
              onKeyPress={validations.validateOnKeyPress('onlyNumber')}
              validate={validations.mix(validations.required(), validations.minLength(6))}
            />
            <article className="pb-7.5 md:pb-7 flex">
              <p>{sendTexts[sendKey].unsent}</p>
              {timerConfig.show ? (
                <Timer timerConfig={timerConfig} setTimerConfig={setTimerConfig} timerMessage="para reenviar" />
              ) : (
                <ButtonOption description="Enviar nuevo código" className="ml-1" handleClick={handleCodeResend} />
              )}
            </article>
            {submitError && <p className="pb-6 font-bold text-center text-red-500">{submitError}</p>}
            <SaveButton label="Continuar" size="full" className="!mb-4" disabled={pristine || submitting} />
            <ButtonOption
              description={formTexts[flowType].bottomButton}
              className="mx-auto"
              handleClick={handleBottomButton}
            />
            {showBottomOptions && (
              <article className="grid grid-col-1 gap-4 border-t-2 border-blue-200 mt-6">
                <p className="mt-6">{formTexts[flowType].bottomOptions}</p>
                {flowType === 'login' && (
                  <LinkOption pathname="/" icon={PadlockIcon} description="Ingresa con tu contraseña" />
                )}
                <ButtonOption
                  icon={sendTexts[sendKey].changeIcon}
                  description={sendTexts[sendKey].changeContact}
                  className="mx-auto"
                  textClassName="ml-3"
                  handleClick={() => handleSendCodeButton(isPhoneSend ? 'mail' : 'sms')}
                />
                {flowType === 'recovery' && (
                  <>
                    <LinkOption
                      pathname="/recuperar-contrasena-pide"
                      icon={PersonalQuestionsIcon}
                      description="Valida tu identidad respondiendo preguntas personales"
                    />
                    <ButtonOption
                      icon={UserCheckIcon}
                      description="Valida tu identidad mediante clave SOL"
                      className="mx-auto"
                      textClassName="ml-3"
                      handleClick={() => redirectTo('SUNKEYREC')}
                    />
                  </>
                )}
              </article>
            )}
            {(submitSucceeded || !showCodeError) && form.reset()}
            {(submitSucceeded || !showCodeError) && form.resetFieldState('code')}
          </form>
        )}
      />
    </section>
  )
}

LinkOption.propTypes = {
  pathname: PropTypes.string,
  icon: PropTypes.string,
  description: PropTypes.string
}

ButtonOption.propTypes = {
  icon: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
  textClassName: PropTypes.string,
  handleClick: PropTypes.func
}

CodeVerifyForm.propTypes = {
  title: PropTypes.string,
  contact: PropTypes.string,
  handleSubmitForm: PropTypes.func,
  handleCodeResend: PropTypes.func,
  handleBottomButton: PropTypes.func,
  showBottomOptions: PropTypes.bool,
  timerConfig: PropTypes.object,
  setTimerConfig: PropTypes.func,
  isPhoneSend: PropTypes.bool,
  handleSendCodeButton: PropTypes.func,
  showCodeError: PropTypes.bool,
  flowType: PropTypes.string
}

export default CodeVerifyForm
