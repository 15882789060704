import React from 'react'
import { Link } from 'react-router-dom'

import { Breadcrumbs } from '@material-ui/core'
import PropTypes from 'prop-types'

import ChevronLeftIcon from '../../assets/images/chevron_left_icon.svg'
import ChevronRightIcon from '../../assets/images/chevron_right_icon.svg'
import { allRoutes } from '../../configs/routes'
import { Container } from '../Container'

const Breadcrumb = ({ routesKey, data }) => {
  const breadcrumbContent = Array.isArray(data) ? [...data] : [data]
  const breadcrumbRoutes = data !== null ? [...allRoutes[routesKey], ...breadcrumbContent] : allRoutes[routesKey]
  const { route, label } = breadcrumbRoutes.find(route => route.showInMobile)

  return (
    <Container className="px-5 md:px-20 py-2.5">
      <article className="hidden md:block">
        <Breadcrumbs separator={<img src={ChevronRightIcon} className="mx-2" />} aria-label="breadcrumb">
          {breadcrumbRoutes.map((item, index) => {
            if (item.route) {
              return (
                <Link key={index} to={item.route} className="font-bold text-blue-700 text-sm">
                  {item.label}
                </Link>
              )
            } else {
              return (
                <span key={index} className="font-bold text-sm text-gray-850">
                  {item.label}
                </span>
              )
            }
          })}
        </Breadcrumbs>
      </article>
      <article className="block md:hidden">
        <Link to={route} className="font-bold text-blue-700 text-sm flex items-center">
          <img src={ChevronLeftIcon} className="mr-2.5" />
          <span>{label}</span>
        </Link>
      </article>
    </Container>
  )
}

Breadcrumb.propTypes = {
  routesKey: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

export default Breadcrumb
