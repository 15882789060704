import React, { createContext, useContext, useEffect, useReducer } from 'react'

import PropTypes from 'prop-types'

import { getJsonItemFromLocalStorage, setItemToLocalStorage } from '../helpers/helpers'

const AuthContext = createContext(null)

const init = () => getJsonItemFromLocalStorage('userData') || { logged: false }
const initAdmin = () => getJsonItemFromLocalStorage('adminData') || { logged: false }

const reducer = (state = {}, action) => {
  switch (action.type) {
    case 'Login':
      return {
        ...action.payload,
        logged: true
      }

    case 'Logout':
      return {
        logged: false
      }

    case 'Registration':
      return {
        ...action.payload,
        registry: true
      }

    case 'Verification':
      return {
        ...action.payload,
        verified: true
      }

    case 'Recovery':
      return {
        ...action.payload,
        changePassword: true
      }
    default:
      return state
  }
}

const adminReducer = (state = {}, action) => {
  switch (action.type) {
    case 'Login':
      return {
        ...action.payload,
        logged: true
      }

    case 'Logout':
      return {
        logged: false
      }

    default:
      return state
  }
}

const Auth = ({ children }) => {
  const [user, dispatch] = useReducer(reducer, {}, init)
  const [admin, adminDispatch] = useReducer(adminReducer, {}, initAdmin)

  useEffect(() => {
    if (!user) return
    setItemToLocalStorage('userData', JSON.stringify(user))
  }, [user])

  useEffect(() => {
    if (!admin) return
    setItemToLocalStorage('adminData', JSON.stringify(admin))
  }, [admin])

  return <AuthContext.Provider value={{ user, dispatch, admin, adminDispatch }}>{children}</AuthContext.Provider>
}

Auth.propTypes = {
  children: PropTypes.element
}

export const useAuth = () => useContext(AuthContext)

export default Auth
