import React, { useState } from 'react'
import { Form } from 'react-final-form'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import { appName, getJsonItemFromLocalStorage } from '../../helpers/helpers'
import { Button } from '../fields/Button'
import HiddenField from '../fields/HiddenField'
import PasswordField from '../fields/PasswordField'
import SaveButton from '../fields/SaveButton'
import TextField from '../fields/TextField'
import * as validations from '../fields/Validations'
import Alert from '../ui/Alert'
import LoginHeader from '../users/LoginHeader'

const LoginHead = ({ alert, setAlert }) => (
  <>
    <LoginHeader
      title={appName}
      subtitle="Revisa tus documentos, el avance de tus trámites y las notificaciones que te envían las entidades públicas."
      className="pb-7.5 md:pb-10 md:text-center"
    />
    {alert && (
      <Alert type={alert.type} autoHideDuration={5000} setActionAlert={setAlert}>
        <span>{`${alert.text}`}</span>
      </Alert>
    )}
  </>
)

const LoginForm = ({ handleSubmitForm, handleIdPeruButton, handleIdGobPeButton }) => {
  const [valActionAlert, setActionAlert] = useState(getJsonItemFromLocalStorage('actionAlert'))
  const [codeLogin, setCodeLogin] = useState(false)

  return (
    <section className="pt-8 md:pt-14 pb-14 md:pb-18 m-auto max-w-xl">
      <LoginHead alert={valActionAlert} setAlert={setActionAlert} />
      <Form
        onSubmit={handleSubmitForm}
        subscription={{ submitError: true, submitting: true, pristine: true }}
        render={({ handleSubmit, submitError, submitting, pristine }) => (
          <form className="p-6 md:p-9 border border-blue-200 rounded shadow-auth-register" onSubmit={handleSubmit}>
            <h2 className="font-bold text-center text-xl md:text-2xl pb-6 md:pb-7.5">
              Ingresa tus datos para iniciar sesión
            </h2>
            {codeLogin && (
              <p className="pb-6 md:text-center">
                Recibirás un código de verificación en tu correo electrónico o número de celular registrado
              </p>
            )}
            <HiddenField name="codeSelected" value={codeLogin} />
            <TextField label="Número de DNI" name="document_number" size="full" validate={validations.required()} />
            {!codeLogin && (
              <PasswordField
                name="password"
                label="Ingresa tu contraseña"
                size="full"
                labelClassName="mb-2"
                checkboxLabel="Mostrar contraseña"
                checkboxName="show_password"
                checkboxClassName="pb-7"
                validate={validations.required()}
              />
            )}
            {submitError && <p className="text-red-500 font-bold text-center pb-6">{submitError}</p>}
            <SaveButton label={codeLogin ? 'Continuar' : 'Ingresar'} size="full" disabled={submitting || pristine} />
            <article className="border-b border-gray-250 text-center pt-2 pb-6 mb-6">
              <p>o</p>
              <button
                type="button"
                className="font-medium text-blue-700 underline"
                onClick={() => setCodeLogin(!codeLogin)}
              >
                {codeLogin ? 'Inicia sesión con usuario y contraseña' : 'Inicia sesión con código'}
              </button>
              {!codeLogin && (
                <div className="grid grid-cols-1 gap-4 mt-4">
                  <Button size="full" variant="secondary" onClick={handleIdPeruButton}>
                    Inicia sesión con autenticación facial
                  </Button>
                  <Button size="full" variant="secondary" onClick={handleIdGobPeButton}>
                    Inicia sesión con ID Gob.pe
                  </Button>
                </div>
              )}
            </article>
            <article className="text-center">
              <p className="font-medium">¿No tienes cuenta en Carpeta Ciudadana?</p>
              <Link to="/validar-identidad" className="font-medium text-blue-700 underline">
                Regístrate
              </Link>
              <p className="font-medium pt-5">¿Olvidaste tu contraseña?</p>
              <Link to="/recuperar-contrasena" className="font-medium text-blue-700 underline">
                Genera una nueva contraseña
              </Link>
            </article>
          </form>
        )}
      />
    </section>
  )
}

LoginHead.propTypes = {
  alert: PropTypes.object,
  setAlert: PropTypes.func
}

LoginForm.propTypes = {
  handleSubmitForm: PropTypes.func,
  handleIdPeruButton: PropTypes.func,
  handleIdGobPeButton: PropTypes.func
}

export default LoginForm
