import React from 'react'
import { Form } from 'react-final-form'
import { useNavigate } from 'react-router-dom'

import { FORM_ERROR } from 'final-form'

import { request } from '../../api/apiCore'
import { useAuth } from '../../auth/Auth'
import { Container } from '../../components/Container'
import { Button } from '../../components/fields/Button'
import CheckboxField from '../../components/fields/CheckboxField'
import SaveButton from '../../components/fields/SaveButton'
import TextField from '../../components/fields/TextField'
import * as validations from '../../components/fields/Validations'
import FormsHeader from '../../components/ui/FormsHeader'
import ScrollToTop from '../../components/ui/ScrollToTop'
import { UserFormSteps } from '../../configs/users'
import { getJsonItemFromLocalStorage, removeItemFromLocalStorage, setItemToLocalStorage } from '../../helpers/helpers'

export const UserInfoStepPage = () => {
  const {
    user: { jwt },
    dispatch
  } = useAuth()

  const initialData = getJsonItemFromLocalStorage('userRegistryData')
  const navigate = useNavigate()

  const onSubmit = async values => {
    try {
      const verifyEmail = await request({
        url: '/registration/verify-email.json',
        method: 'POST',
        data: { ...values }
      })

      if (verifyEmail.response?.data.error)
        return { [FORM_ERROR]: `Ocurrió un error: ${verifyEmail.response.data.error}` }

      setItemToLocalStorage('userRegistryData', JSON.stringify({ ...initialData, ...values }))
      dispatch({ type: 'Registration', payload: { ...verifyEmail, jwt } })
      navigate('/verificar-codigo')
    } catch (error) {
      return { [FORM_ERROR]: `Ocurrió un error: ${error.response.data.message}` }
    }
  }

  const handleCancel = () => {
    removeItemFromLocalStorage(['userRegistryData'])
    dispatch({ type: 'Logout' })
  }

  return (
    <Container className="px-5 pt-8 pb-14 md:px-20 md:pt-14 md:pb-18">
      <FormsHeader
        title="Creando mi cuenta de carpeta ciudadana"
        steps={UserFormSteps}
        activeStep={UserFormSteps.SET_INFO}
      />
      <div className="p-6 md:p-9 border border-blue-200 shadow-auth-register m-auto max-w-lg rounded">
        <h2 className="md:text-center pb-6 md:pb-7 font-bold text-xl md:text-2xl">
          Información personal y de contacto
        </h2>
        <Form
          onSubmit={onSubmit}
          initialValues={initialData}
          subscription={{ submitting: true, submitError: true }}
          render={({ handleSubmit, submitError, submitting }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                size="full"
                name="name"
                label="Nombres"
                disabled={'name' in initialData && initialData.name !== ''}
                validate={validations.required()}
              />
              <TextField
                size="full"
                name="father_last_name"
                label="Apellido paterno"
                disabled={'father_last_name' in initialData && initialData.father_last_name !== ''}
                validate={validations.required()}
              />
              <TextField
                size="full"
                name="mother_last_name"
                label="Apellido materno"
                disabled={'mother_last_name' in initialData && initialData.mother_last_name !== ''}
                validate={validations.required()}
              />
              <TextField
                size="full"
                name="email"
                label="Correo electrónico"
                validate={validations.mix(validations.required(), validations.isEmail())}
              />
              <TextField
                size="full"
                name="phone_number"
                label="Número de celular"
                margin="dense"
                validate={validations.mix(
                  validations.required(),
                  validations.isPeruvianCellphone(),
                  validations.maxLength(9)
                )}
              />
              <CheckboxField
                label={
                  <p className="leading-5">
                    Acepto las{' '}
                    <a className="text-blue-700 font-medium" href="/condiciones_uso.pdf" target="_blank">
                      condiciones de uso
                    </a>
                  </p>
                }
                name="checkConditions"
                margin="none"
                formControlClassName="!gap-5"
                className="pb-3.5 md:pb-3"
                validate={validations.required()}
              />
              <CheckboxField
                label={
                  <p className="leading-5">
                    Declaro que he leído la{' '}
                    <a className="text-blue-700 font-medium" href="/politica_privacidad.pdf" target="_blank">
                      política de privacidad
                    </a>
                  </p>
                }
                name="checkPolicies"
                margin="none"
                formControlClassName="!gap-5"
                className="!block pb-7.5 md:pb-7"
                validate={validations.required()}
              />
              {submitError && <div className="text-red-500 font-bold text-center pb-6">{submitError}</div>}
              <div className="pb-3.75">
                <SaveButton label="Continuar" size="full" disabled={submitting} />
              </div>
              <Button size="full" variant="text" onClick={handleCancel}>
                Cancelar
              </Button>
            </form>
          )}
        />
      </div>
      <ScrollToTop />
    </Container>
  )
}
