import React from 'react'
import { ReactSVG } from 'react-svg'

import clsx from 'clsx'
import PropTypes from 'prop-types'

import InformationAlertIcon from '../../assets/images/danger.svg'
import AlertCard from '../ui/AlertCard'
import HeadingTag from '../ui/HeadingTag'
import MessageCreateOptions from './MessageCreateOptions'

const MessagesHeader = ({ moduleData, handleSendButton }) => (
  <section
    className={clsx('pb-7.5 md:pb-0 mb-7.5 md:mb-12 border-b md:border-b-0', {
      'md:flex ': moduleData.state
    })}
  >
    <article className="md:w-4/5 md:pr-5">
      <HeadingTag
        title="Notificaciones y comunicaciones"
        className="font-bold text-3xl md:text-4xl"
        id="messages-label"
      />
      <p className="mt-4 md:mt-6 max-w-2xl">
        En esta sección podrás enviar notificaciones y comunicaciones a la ciudadanía que tiene trámites o procesos
        activos con tu entidad.
      </p>
    </article>
    {!moduleData.state ? (
      <AlertCard
        icon={<ReactSVG src={InformationAlertIcon} />}
        content={moduleData?.description}
        type="alert"
        className="mt-8 md:mt-14 mb-14 md:mb-18"
      />
    ) : (
      <MessageCreateOptions onClickButton={handleSendButton} />
    )}
  </section>
)

MessagesHeader.propTypes = {
  moduleData: PropTypes.object,
  handleSendButton: PropTypes.func
}

export default MessagesHeader
