import React from 'react'
import { useField } from 'react-final-form'
import { ReactSVG } from 'react-svg'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import checkboxIcon from '../../assets/images/check_icon.svg'
import checkedIcon from '../../assets/images/checked_icon.svg'
import Error from './Error'

const marginMap = {
  normal: 'mb-8',
  dense: 'mb-4',
  none: 'mb-0'
}

const useStyles = makeStyles({
  checkboxInput: {
    margin: 0,
    padding: 0,
    color: '#26292e',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  contolLabel: {
    margin: 0,
    padding: 0
  }
})

const CheckboxField = ({
  name,
  validate,
  label,
  margin = 'normal',
  className,
  formControlClassName,
  errorClassName,
  hideError = false,
  disabled = false,
  icon = <ReactSVG src={checkboxIcon} />,
  checkIcon = <ReactSVG src={checkedIcon} />,
  ...props
}) => {
  const { input } = useField(name, { validate, type: 'checkbox', ...props })
  const classes = useStyles()
  return (
    <div className={clsx(className, marginMap[margin])}>
      <FormControlLabel
        control={
          <Checkbox
            {...input}
            disableRipple
            color="default"
            name={name}
            disabled={disabled}
            icon={icon}
            checkedIcon={checkIcon}
            classes={{ root: classes.checkboxInput }}
            {...props}
          />
        }
        classes={{ root: classes.contolLabel, label: '!font-medium' }}
        className={formControlClassName}
        label={label}
      />
      {!hideError && <Error name={name} className={clsx('pt-2', errorClassName)} />}
    </div>
  )
}

export default CheckboxField

CheckboxField.propTypes = {
  name: PropTypes.string,
  validate: PropTypes.func,
  label: PropTypes.node,
  margin: PropTypes.string,
  className: PropTypes.string,
  formControlClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  hideError: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  checkIcon: PropTypes.element
}
