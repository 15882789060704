import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { CategoryEdit } from '../components/admin/documents/CategoryEdit'
import { CategoryNew } from '../components/admin/documents/CategoryNew'
import { DocumentEdit } from '../components/admin/documents/DocumentEdit'
import { DocumentNew } from '../components/admin/documents/DocumentNew'
import { RoleEdit } from '../components/admin/roles/RoleEdit'
import { RoleNew } from '../components/admin/roles/RoleNew'
import { ResourcesTab } from '../components/ui/ResourcesTab'
import { AddresseeDataPage } from '../pages/admin/AddresseeDataPage'
import { MessageDetailPage } from '../pages/admin/MessageDetailPage'
import { MessageInfoPage } from '../pages/admin/MessageInfoPage'
import { MessageReviewPage } from '../pages/admin/MessageReviewPage'
import { MultipleSendingPage } from '../pages/admin/MultipleSendingPage'
import { AreaEdit } from '../pages/admin/areas/AreaEdit'
import { AreaNew } from '../pages/admin/areas/AreaNew'
import { LabourHoursPage } from '../pages/admin/modules/LabourHoursPage'
import { ServiceInfoPage } from '../pages/admin/modules/ServiceInfoPage'
import { UserDetail } from '../pages/admin/users/UserDetail'
import { UserEdit } from '../pages/admin/users/UserEdit'
import { UserNew } from '../pages/admin/users/UserNew'
import { WebServiceEdit } from '../pages/admin/webServices/WebServiceEdit'
import { WebServiceNew } from '../pages/admin/webServices/WebServiceNew'

export const AdminRoutes = () => (
  <Routes>
    <Route path="inicio" element={<ResourcesTab />} />
    <Route path="gob_users/crear" element={<UserNew />} />
    <Route path="gob_users/edit/:id" element={<UserEdit />} />
    <Route path="gob_users/:id" element={<UserDetail />} />
    <Route path="roles-y-ciudadanos" element={<ResourcesTab />} />
    <Route path="roles/crear" element={<RoleNew />} />
    <Route path="roles/edit/:id" element={<RoleEdit />} />
    <Route path="notificaciones" element={<ResourcesTab />} />
    <Route path="notificaciones/:noti_id" element={<MessageDetailPage />} />
    <Route path="datos-destinatario" element={<AddresseeDataPage />} />
    <Route path="datos-destinatarios" element={<MultipleSendingPage />} />
    <Route path="informacion-mensaje" element={<MessageInfoPage />} />
    <Route path="revision-mensaje" element={<MessageReviewPage />} />
    <Route path="areas-y-usuarios" element={<ResourcesTab />} />
    <Route path="areas/crear" element={<AreaNew />} />
    <Route path="areas/edit/:id" element={<AreaEdit />} />
    <Route path="documentos-y-categorias" element={<ResourcesTab />} />
    <Route path="documentos/crear" element={<DocumentNew />} />
    <Route path="documentos/edit/:id" element={<DocumentEdit />} />
    <Route path="categorias/crear" element={<CategoryNew />} />
    <Route path="categorias/edit/:id" element={<CategoryEdit />} />
    <Route path="modulos" element={<ResourcesTab />} />
    <Route path="modulos/:module_id/servicio" element={<ServiceInfoPage />} />
    <Route path="modulos/:module_id/horarios" element={<LabourHoursPage />} />
    <Route path="feedbacks" element={<ResourcesTab />} />
    <Route path="web-services" element={<ResourcesTab />} />
    <Route path="web-services/crear" element={<WebServiceNew />} />
    <Route path="web-services/edit/:id" element={<WebServiceEdit />} />
    <Route path="*" element={<Navigate to="inicio" replace />} />
  </Routes>
)
