import React from 'react'

import { useAuth } from '../../../auth/Auth'
import { ORDER_OPTIONS } from '../../../constants/globals'
import { urlSearchParamsData } from '../../../helpers/helpers'
import useFilters from '../../../hooks/useFilters'
import { useUpdateGobUser } from '../../../hooks/useGobUsers'
import BoxTable from '../../ui/BoxTable'
import UsersTable from './UsersTable'

export const Users = () => {
  const { institution_gobpe_id } = urlSearchParamsData()
  const { admin } = useAuth()
  const { mutate: patchGobUser } = useUpdateGobUser()
  const { onFilter, initialFilters, results } = useFilters('admin/gob_users.json', { sort: 'created_at desc' })

  const urlUser =
    admin.is_admin_carpeta && admin.institution_gobpe_id?.value
      ? `/admin/gob_users/crear?institution_gobpe_id=${institution_gobpe_id}`
      : '/admin/gob_users/crear'

  const customOnFilter = values => {
    delete values.status
    onFilter({ ...values, institution_gobpe_id: institution_gobpe_id || '' })
  }

  const handleStatusChange = (id, value) => patchGobUser({ id, active: value })

  return (
    <BoxTable
      title="Usuarios"
      description={`Máximo ${results.institution?.num_max_gobuser} usuarios por entidad. Si necesita crear más comuníquese con el administrador de la plataforma.`}
      initialFilters={initialFilters}
      onFilter={customOnFilter}
      buttons={[
        {
          Text: 'Crear nuevo usuario',
          cardUrl: urlUser,
          isActiveButton: results.total_count < results.institution?.num_max_gobuser,
          className: 'bg-white'
        }
      ]}
      sortOptions={ORDER_OPTIONS}
      values={results}
      searchPlaceholder="Buscar por nombre"
      displayTotal={true}
      searchFieldName="search_user"
      className="bg-gray-40 rounded"
    >
      <UsersTable users={results.data || []} totalPages={results.total_pages} onStatusChange={handleStatusChange} />
    </BoxTable>
  )
}
