import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { FORM_ERROR } from 'final-form'
import jwtDecode from 'jwt-decode'

import { request } from '../api/apiCore'
import { useAuth } from '../auth/Auth'
import { Container } from '../components/Container'
import CodeVerifyForm from '../components/forms/CodeVerifyForm'
import FormsHeader from '../components/ui/FormsHeader'
import ScrollToTop from '../components/ui/ScrollToTop'
import { UserFormSteps } from '../configs/users'
import { getJsonItemFromLocalStorage, hideText } from '../helpers/helpers'

export const CodeVerifyPage = () => {
  const { user, dispatch } = useAuth()
  const [valError, setValError] = useState(true)
  const [timerConfig, setTimerConfig] = useState({ show: true, reset: false })
  const [verifySms, setVerifySms] = useState(user.send_type === 'sms')
  const navigate = useNavigate()

  const errorObject = { [FORM_ERROR]: 'Código ingresado es incorrecto o ya caducó' }

  const validateToken = async code => {
    const { salt, document_number, email, phone_number } = user
    let data = { code, salt, document_number }
    data = verifySms ? { ...data, phone_number } : { ...data, email }
    const { token } = await request({ url: '/authentication/verify-code', data, method: 'POST' })

    if (!token) {
      setValError(true)
      return { token, hasError: true }
    }

    return { token, hasError: false }
  }

  const validateSalt = salt => {
    if (!salt) {
      setTimerConfig({ ...timerConfig, show: false })
      return { [FORM_ERROR]: 'No se pudo reenviar codigo' }
    }
  }

  const resendDispatch = salt => {
    const type = user.verified ? 'Verification' : user.registry ? 'Registration' : 'Recovery'
    dispatch({ type, payload: { ...user, salt } })
  }

  const handleSubmit = async ({ code }) => {
    const { salt, document_number, jwt } = user

    try {
      if (user.verified) {
        const { token, hasError } = await validateToken(code)
        if (hasError) return errorObject

        const { user_id: id, name, father_last_name } = jwtDecode(token)
        const payload = { id, name, father_last_name, jwt: token, document_number, type: 'cue' }
        dispatch({ type: 'Login', payload })
      }

      if (user.registry) {
        const data = { code, salt }
        const verifyCode = await request({ url: '/registration/verify-code', data, method: 'POST' })

        if (verifyCode.response?.data?.error) {
          setValError(true)
          return errorObject
        }

        if (!verifySms) {
          const verifyPhoneNumber = await request({
            url: '/registration/verify-phone-number.json',
            method: 'POST',
            data: getJsonItemFromLocalStorage('userRegistryData')
          })

          if (verifyPhoneNumber.response?.data.error)
            return { [FORM_ERROR]: `Ocurrió un error: ${verifyPhoneNumber.response.data.error}` }

          dispatch({ type: 'Registration', payload: { ...verifyPhoneNumber, jwt } })
          setVerifySms(true)
          setTimerConfig({ ...timerConfig, reset: true })
        } else {
          dispatch({ type: 'Registration', payload: { ...verifyCode } })
          navigate('/contrasena-usuario')
        }
      }

      if (user.changePassword) {
        const { token, hasError } = await validateToken(code)
        if (hasError) return errorObject

        dispatch({ type: 'Recovery', payload: { token } })
        navigate('/contrasena-usuario')
      }
    } catch (error) {
      return { [FORM_ERROR]: `Ocurrió un error ${error}` }
    }
  }

  const handleResend = async type => {
    try {
      setTimerConfig({ ...timerConfig, show: true })
      const send_type = typeof type === 'string' ? type : verifySms ? 'sms' : 'mail'
      const data = { send_type, document_type_code: 'dni', document_number: user.document_number }

      if (user.verified || user.changePassword) {
        const { salt } = await request({ url: '/authentication/send-code.json', data, method: 'POST' })
        validateSalt(salt)
        resendDispatch(salt)
      }

      if (user.registry) {
        const { salt } = await request({ url: '/registration/resend-code.json', data, method: 'POST' })
        validateSalt(salt)
        resendDispatch(salt)
      }
    } catch (error) {
      setTimerConfig({ ...timerConfig, show: false })
      return { [FORM_ERROR]: `Ocurrió un error ${error}` }
    }
  }

  const handleSendCode = sendType => {
    setVerifySms(!verifySms)
    setValError(false)
    handleResend(sendType)
  }

  const handleButton = () => {
    setValError(false)
    if (user.registry && !verifySms) navigate(-1)

    if (verifySms) {
      setTimerConfig({ ...timerConfig, show: false })
      setVerifySms(false)
    }

    if (user.verified || user.changePassword) navigate(-1)
  }

  const getTitle = () => {
    if (user.verified) return `¡Hola, ${user.name}!`
    if (user.registry) return !verifySms ? 'Validación de correo electrónico' : 'Validación de número de celular'
    if (user.changePassword) return 'Recuperar contraseña'
  }

  const getFlowType = () => {
    if (user.verified) return 'login'
    if (user.registry) return 'registry'
    if (user.changePassword) return 'recovery'
  }

  const getContact = () => {
    const hidden = user.verified || user.changePassword
    const contact = verifySms ? user.phone_number : user.email
    const type = verifySms ? 'cellphone' : 'email'

    return hidden ? hideText(contact, 2, type) : contact
  }

  return (
    <Container className="px-5 pt-8 2xl:h-screen pb-14 md:px-20 md:pt-14 md:pb-18">
      {user.registry && (
        <FormsHeader
          title="Creando mi cuenta de carpeta ciudadana"
          steps={UserFormSteps}
          activeStep={!verifySms ? UserFormSteps.SET_CODE : UserFormSteps.SET_CODE_SMS}
        />
      )}
      <CodeVerifyForm
        title={getTitle()}
        contact={getContact()}
        handleSubmitForm={handleSubmit}
        handleCodeResend={handleResend}
        handleBottomButton={handleButton}
        showBottomOptions={user.verified || user.changePassword}
        isPhoneSend={verifySms}
        handleSendCodeButton={handleSendCode}
        showCodeError={valError}
        timerConfig={timerConfig}
        setTimerConfig={setTimerConfig}
        flowType={getFlowType()}
      />
      <ScrollToTop />
    </Container>
  )
}
