import { useMutation, useQuery, useQueryClient } from 'react-query'

import { request } from '../api/apiCore'

const fetchGobUser = id => request({ url: `/admin/gob_users/${id}.json` })

const updateGobUser = data => request({ url: `/admin/gob_users/${data.id}.json`, data, method: 'PATCH' })

export const useGobUserData = id => useQuery(['gob_user', id], () => fetchGobUser(id))

export const useUpdateGobUser = () => {
  const queryClient = useQueryClient()

  return useMutation(updateGobUser, {
    onSuccess: () => {
      queryClient.invalidateQueries('admin/gob_users.json')
    }
  })
}
