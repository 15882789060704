import React, { useContext } from 'react'
import { Form } from 'react-final-form'
import { useNavigate } from 'react-router-dom'

import { FORM_ERROR } from 'final-form'
import { AlertContext } from '~/utils/context'

import { axiosRequest, request } from '../../api/apiCore'
import { Container } from '../../components/Container'
import MessageReceiptsSection from '../../components/admin/MessageReceiptsSection'
import MessageReviewSection from '../../components/admin/MessageReviewSection'
import { Button } from '../../components/fields/Button'
import SaveButton from '../../components/fields/SaveButton'
import Breadcrumb from '../../components/ui/Breadcrumb'
import FormsHeader from '../../components/ui/FormsHeader'
import HeadingTag from '../../components/ui/HeadingTag'
import { MessageCreateSteps, getAttachedFiles, infoMessageReview } from '../../configs/messages'
import { SERVICE_OK } from '../../constants/globals'
import {
  convertDataToBreadcrumbRoute,
  filterObject,
  formatLabelsObject,
  getItemFromLocalStorage,
  getJsonItemFromLocalStorage,
  removeItemFromLocalStorage,
  toCapitalizeCase
} from '../../helpers/helpers'
import useShowMessage from '../../hooks/useShowMessage'

const pageData = {
  notification: {
    title: 'Enviar una notificación',
    breadcrumbText: 'Nueva notificación',
    key: 'notifications',
    description: 'notificación'
  },
  communication: {
    title: 'Enviar una comunicación',
    breadcrumbText: 'Nueva comunicación',
    key: 'communications',
    description: 'comunicación'
  }
}

const removeAuxiliaries = () => removeItemFromLocalStorage(['addresseData', 'messageInfoData', 'messageType'])

export const MessageReviewPage = () => {
  const navigate = useNavigate()
  const { message, setMessage } = useShowMessage()
  const { setAlertMessage } = useContext(AlertContext)

  const messageType = getItemFromLocalStorage('messageType')
  const addresseData = getJsonItemFromLocalStorage('addresseData')
  const messageInfoData = getJsonItemFromLocalStorage('messageInfoData')

  const { title, breadcrumbText, key, description } = pageData[messageType]
  const breadcrumbData = convertDataToBreadcrumbRoute(breadcrumbText)

  const onSubmit = async () => {
    let isSuccess,
      showSendingAlert = false

    const messageData = filterObject(messageInfoData, [
      'areas_id',
      'track_code_type_id',
      'track_code',
      'procedure_id',
      'subject',
      'content',
      'file_infos_attributes'
    ])
    const citizenData = filterObject(addresseData, ['citizen_id_code', 'citizen_id_number'])
    const recipients = Array.isArray(addresseData) && addresseData?.map(({ citizen_id_number }) => citizen_id_number)
    const baseMessage = { ...messageData, message_type: messageType }

    try {
      if (messageType === 'communication') {
        const { id } = await request({
          url: 'admin/messages',
          data: { ...citizenData, ...baseMessage },
          method: 'POST'
        })

        isSuccess = id > 0
        showSendingAlert = true
      } else {
        const response = await axiosRequest({
          url: '/api/v2/admin/messages.json',
          data: { citizen_id_code: 'dni', recipients, ...baseMessage },
          method: 'POST'
        })

        showSendingAlert = response.data?.scheduled_send_text === undefined
        if (!showSendingAlert) setAlertMessage(response.data.scheduled_send_text)
        isSuccess = response.status === SERVICE_OK
      }

      if (isSuccess) {
        if (showSendingAlert) setAlertMessage(`${toCapitalizeCase(description)} enviada exitosamente`)
        removeAuxiliaries()
        navigate('/admin/notificaciones')
      } else {
        showMessage(<div className="text-center pb-5 text-red-500">{`No se pudo enviar ${description}!`}</div>, 5000)
      }
    } catch (error) {
      return { [FORM_ERROR]: `Ocurrió un error: ${error}` }
    }
  }

  const showMessage = (content, time) => {
    setMessage({ ...message, show: true, content })
    time &&
      setTimeout(() => {
        setMessage({ ...message, show: false })
        removeAuxiliaries()
        navigate('/admin/notificaciones')
      }, time)
  }

  return (
    <>
      <Breadcrumb routesKey={key} data={breadcrumbData} />
      <Container className="px-5 pt-8 pb-14 md:px-20 md:pt-14 md:pb-18">
        <FormsHeader title={title} steps={MessageCreateSteps} activeStep={MessageCreateSteps.PREVIEW} />
        <div className="m-auto max-w-130">
          <section className="p-6 md:p-8 border border-blue-200 shadow-auth-register rounded">
            <HeadingTag
              title={MessageCreateSteps.PREVIEW[messageType]}
              as="h2"
              className="md:text-center pb-6 md:pb-7 font-bold text-xl md:text-2xl"
            />
            <MessageReceiptsSection type={messageType} data={addresseData} />
            <MessageReviewSection
              title="Información y contenido del mensaje"
              linkRoute="/admin/informacion-mensaje"
              sectionData={formatLabelsObject(infoMessageReview, messageInfoData)}
              parentClassName="pb-6 mb-6 border-b"
              headingClassName="pb-4 md:pb-4.5"
              titleClassName="font-bold text-lg md:text-xl"
              showEditButton={Object.values(messageInfoData).length > 0}
            />
            <MessageReviewSection
              title="Documentos de sustento a enviar"
              linkRoute="/admin/informacion-mensaje#adjuntos"
              sectionData={getAttachedFiles(messageInfoData.file_infos_attributes, messageType, 'message-review')}
              headingClassName="pb-4 md:pb-4.5"
              titleClassName="font-bold text-lg md:text-xl"
              contentClassName="flex"
              showEditButton={messageInfoData.file_infos_attributes?.length > 0}
            />
            {message.show && message.content}
          </section>
          <Form
            onSubmit={onSubmit}
            subscription={{ submitting: true }}
            render={({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit} className="flex justify-end gap-6 mt-10">
                <Button
                  size="full"
                  className="max-w-45"
                  variant="secondary"
                  onClick={() => navigate('/admin/informacion-mensaje')}
                >
                  Regresar
                </Button>
                <SaveButton label={`Enviar ${description}`} size="full" className="max-w-45" disabled={submitting} />
              </form>
            )}
          />
        </div>
      </Container>
    </>
  )
}
