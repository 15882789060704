import React from 'react'

import AirplaneIcon from '../../assets/images/paper_airplane_icon.svg'
import { CONFIRMATION_TYPES } from '../../constants/globals'
import CheckboxField from '../fields/CheckboxField'
import RadioField from '../fields/RadioField'
import TextField from '../fields/TextField'
import * as validations from '../fields/Validations'

const radioFieldClasses = { label: '!font-medium' }

const First = () => (
  <div className="text-center">
    <h3 className="font-bold text-xl">
      Por favor, responde las siguientes preguntas para ayudarnos a mejorar el servicio.
    </h3>
    <p className="pt-6 pb-5">
      ¿Recibiste un SMS en tu celular como alerta sobre la notificación que llegó a tu Casilla Única Electrónica?
    </p>
    <RadioField
      name="sms_received"
      margin="high"
      formControlLabelClasses={radioFieldClasses}
      options={CONFIRMATION_TYPES}
    />
  </div>
)

const Second = () => (
  <>
    <p className="pb-5 font-bold text-center">
      ¿Recibiste un correo electrónico como alerta sobre la notificación que llegó a tu Casilla Única Electrónica?
    </p>
    <RadioField
      name="email_received"
      margin="high"
      formControlLabelClasses={radioFieldClasses}
      options={CONFIRMATION_TYPES}
    />
  </>
)

const Third = () => (
  <>
    <p className="pb-5 font-bold text-center">
      ¿Pudiste visualizar y descargar todos los documentos adjuntos a la notificación que recibiste en tu Casilla única
      Electrónica?
    </p>
    <RadioField
      name="can_download_attachments"
      margin="high"
      formControlLabelClasses={radioFieldClasses}
      options={[
        { label: 'Sí', value: 'true' },
        { label: 'No', value: 'false' }
      ]}
    />
  </>
)

const Fourth = () => (
  <>
    <p className="pb-6 font-bold text-center">
      Comparte sugerencias, incidentes o comentarios sobre tu experiencia con Casilla Única Electrónica. (Opcional)
    </p>
    <TextField
      name="description"
      placeholder="Me gustaría encontrar..."
      element="textarea"
      size="full"
      rows={5}
      margin="dense"
      className="!h-full"
      validate={validations.mix(validations.minLength(3), validations.maxLength(256))}
    />
    <CheckboxField
      name="contact_the_sender"
      label="Quiero que me contacten para ayudar a mejorar Casilla Única Electrónica."
      formControlClassName="!flex !items-start !gap-5"
      className="!mb-7.5"
      margin="none"
    />
  </>
)

export const Final = () => (
  <div className="text-center">
    <img src={AirplaneIcon} alt="airplane-icon" className="mx-auto" />
    <p className="font-bold pt-5">¡Gracias por tu ayuda!</p>
    <p className="pt-2.5">Tus comentarios son valiosos para mejorar la Casilla Única Electrónica.</p>
  </div>
)

export default [<First key={0} />, <Second key={1} />, <Third key={2} />, <Fourth key={3} />]
