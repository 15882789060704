import React from 'react'
import { useNavigate } from 'react-router-dom'

import PropTypes from 'prop-types'

import { officialMessageTableHeaderCell } from '../../configs/messages'
import MessageTableRow from './MessageTableRow'

const MessageTableHeaderCell = ({ content, className }) => <th className={className}>{content}</th>

const MessagesTable = ({ messageType, tableData }) => {
  const navigate = useNavigate()

  return (
    <section className="overflow-x-auto">
      <table className="admin-messages-table mt-6">
        <thead>
          <tr>
            {officialMessageTableHeaderCell[messageType].map((content, index) => (
              <MessageTableHeaderCell key={index} content={content} />
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData?.map(({ id, ...messageData }, index) => (
            <MessageTableRow
              key={index}
              rowData={messageData}
              onDataCellClick={() => navigate(`/admin/notificaciones/${id}`)}
            />
          ))}
        </tbody>
      </table>
    </section>
  )
}

MessagesTable.propTypes = {
  messageType: PropTypes.string,
  tableData: PropTypes.array
}

MessageTableHeaderCell.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string
}

export default MessagesTable
