import React from 'react'
import { useNavigate } from 'react-router-dom'

import PropTypes from 'prop-types'

import PersonalQuestionsIcon from '../../assets/images/personal_questions_icon.svg'
import CellPhoneSendIcon from '../../assets/images/phone_send_icon.svg'
import UserCheckIcon from '../../assets/images/user_check_icon.svg'
import { Button } from '../fields/Button'
import HeadingTag from '../ui/HeadingTag'
import IconChangeButton from '../ui/IconChangeButton'

const IdentityValidateOptions = ({
  handleQuestionsOptionButton,
  handleValidationOptionButton,
  handleSelfieOptionButton
}) => {
  const navigate = useNavigate()

  return (
    <section className="max-w-max mx-auto md:text-center">
      <HeadingTag title="Elige cómo validar tu identidad" className="font-bold text-3xl" />
      <div className="grid grid-cols-1 gap-4 pt-6 pb-7.5 md:pt-8">
        <IconChangeButton
          label="Valida tu identidad respondiendo preguntas personales"
          handleClick={handleQuestionsOptionButton}
          normalIcon={PersonalQuestionsIcon}
          hoverIcon={PersonalQuestionsIcon}
          pressedIcon={PersonalQuestionsIcon}
        />
        <IconChangeButton
          label="Valida tu identidad mediante clave SOL"
          handleClick={handleValidationOptionButton}
          normalIcon={UserCheckIcon}
          hoverIcon={UserCheckIcon}
          pressedIcon={UserCheckIcon}
        />
        <IconChangeButton
          label="Valida tu identidad mediante autenticación facial"
          handleClick={handleSelfieOptionButton}
          normalIcon={CellPhoneSendIcon}
          hoverIcon={CellPhoneSendIcon}
          pressedIcon={CellPhoneSendIcon}
        />
      </div>
      <Button size="full" variant="secondary" onClick={() => navigate('/', { replace: true })}>
        Cancelar
      </Button>
    </section>
  )
}

IdentityValidateOptions.propTypes = {
  handleQuestionsOptionButton: PropTypes.func,
  handleValidationOptionButton: PropTypes.func,
  handleSelfieOptionButton: PropTypes.func
}

export default IdentityValidateOptions
