import React from 'react'

import PropTypes from 'prop-types'

import { addresseMessageContent, getAttachedFiles, infoMessageContent, senderDataLabels } from '../../configs/messages'
import { formatLabelsObject } from '../../helpers/helpers'
import { Container } from '../Container'
import HeadingTag from '../ui/HeadingTag'
import MessageContentAside from './MessageContentAside'
import MessageContentSection from './MessageContentSection'
import MessageReviewContent from './MessageReviewContent'

const SubjectTag = ({ text }) => (
  <article className="bg-purple-100 w-fit text-purple-200 md:text-xl py-2 md:py-2.25 px-3 md:px-3.75 font-medium rounded">
    {text}
  </article>
)

const MessageContent = ({ message }) => (
  <Container className="pt-8 md:pt-14">
    <section className="px-5 md:px-20">
      <HeadingTag title={message.user_full_name} className="font-bold text-2xl md:text-3xl pb-4 md:pb-6" />
      <SubjectTag text={message.message_type === 'notification' ? message.track_code_type_name : message.subject} />
    </section>
    <section className="md:flex mt-7.5 md:mt-10 bg-gray-60 px-5 md:px-20 pt-6 md:pt-10 pb-14 md:pb-18 border-t border-gray-80">
      <MessageContentAside message={message} />
      <article className="p-6 md:p-10 md:ml-6 mt-4 md:mt-0 bg-white border border-blue-200 rounded shadow-auth-register md:w-2/3">
        <MessageContentSection
          title="Datos del destinatario"
          sectionData={formatLabelsObject(addresseMessageContent, message)}
          className="md:grid grid-cols-2 gap-2.5"
        />
        <MessageContentSection
          title="Datos del remitente"
          sectionData={formatLabelsObject(senderDataLabels[message.message_type], message)}
          className="md:grid grid-cols-1 gap-2.5"
        />
        <section className="border-blue-200">
          <h2 className="text-xl md:text-2xl pb-4 md:pb-5 font-bold">Información y contenido del mensaje</h2>
          <article className="grid grid-cols-1 gap-2.5">
            {formatLabelsObject(infoMessageContent, message).map((data, index) => (
              <MessageReviewContent key={index} data={data} />
            ))}
          </article>
          {getAttachedFiles(message.files, message.message_type, 'message-content').length > 0 && (
            <>
              <p className="font-bold pt-4 md:pt-4.5 pb-3">Documentos de sustento adjuntos:</p>
              <article className="grid grid-cols-1 gap-4">
                {getAttachedFiles(message.files, message.message_type, 'message-content').map((data, index) => (
                  <MessageReviewContent key={index} data={data} classNameRoot="ml-2 md:ml-4" />
                ))}
              </article>
            </>
          )}
        </section>
      </article>
    </section>
  </Container>
)

MessageContent.propTypes = {
  message: PropTypes.object
}

SubjectTag.propTypes = {
  text: PropTypes.string
}

export default MessageContent
