export const UserFormSteps = {
  SET_INFO: {
    label: 'Ingresa tu información personal y de contacto'
  },
  SET_CODE: {
    label: 'Validación de correo electrónico'
  },
  SET_CODE_SMS: {
    label: 'Validación de número de celular'
  },
  SET_PASSWORD: {
    label: 'Creación de contraseña'
  }
}

export const registerLevel = {
  PIDE: 1,
  CLAVE_SOL: 2,
  ID_GOBPE: 3,
  ID_PERU: 4
}

export const allowedModules = [{ label: 'Notificaciones', value: '1' }]
