import React, { useEffect } from 'react'

import Drawer from '@material-ui/core/Drawer'
import PropTypes from 'prop-types'

import { request } from '../../api/apiCore'
import useToggle from '../../hooks/useToogle'
import FeedbackWizard from './FeedbackWizard'

const boolOptions = { 'true': true, 'false': false }

const DrawerButton = ({ label, className, handleClick }) => (
  <button
    className={`bg-blue-700 px-2.5 py-4 rounded-l-lg [writing-mode:sideways-lr] ${className}`}
    onClick={handleClick}
  >
    <span className="text-white font-medium underline [writing-mode:vertical-lr] rotate-180">{label}</span>
  </button>
)

const FeedbackDrawer = ({ anchor = 'right', buttonLabel, delayToOpen = 10 }) => {
  const [open, setOpen] = useToggle(false)

  const handleSubmit = async values => {
    const data = {
      ...values,
      can_download_attachments: boolOptions[values.can_download_attachments],
      route: location.pathname
    }

    try {
      await request({ url: '/feedbacks-user', method: 'POST', data })
    } catch (error) {
      console.error('Feedback creation error:', error)
    }
  }

  useEffect(() => {
    setTimeout(() => setOpen(true), delayToOpen * 1000)
  }, [])

  return (
    <>
      <DrawerButton
        label={buttonLabel}
        className={`absolute top-1/3 right-0 ${open && 'invisible'}`}
        handleClick={setOpen}
      />
      <Drawer
        anchor={anchor}
        open={open}
        onClose={setOpen}
        classes={{ paper: '!h-auto !max-w-114 !top-1/4 !flex-row !items-center !bg-transparent !shadow-none' }}
      >
        <DrawerButton label={buttonLabel} handleClick={setOpen} />
        <FeedbackWizard onDrawerClose={setOpen} onSubmit={handleSubmit} />
      </Drawer>
    </>
  )
}

export default FeedbackDrawer

FeedbackDrawer.propTypes = {
  anchor: PropTypes.string,
  buttonLabel: PropTypes.string,
  delayToOpen: PropTypes.number
}

DrawerButton.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  handleClick: PropTypes.func
}
