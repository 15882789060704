import React, { Children, useEffect, useState } from 'react'
import { Form } from 'react-final-form'

import PropTypes from 'prop-types'

import { Button } from '../fields/Button'
import SaveButton from '../fields/SaveButton'

const Wizard = ({ onSubmit, initialValues, children, setPage, setIsLastStep }) => {
  const [state, setState] = useState({ page: 0, values: initialValues || {} })

  const next = values => setState({ page: Math.min(state.page + 1, Children.count(children) - 1), values })

  const previous = () => setState({ ...state, page: Math.max(state.page - 1, 0) })

  const validate = values => {
    const activePage = Children.toArray(children)[state.page]
    return activePage.props.validate ? activePage.props.validate(values) : {}
  }

  const handleSubmit = values => {
    const isLastPage = state.page === Children.count(children) - 1
    if (isLastPage) {
      setIsLastStep(true)
      return onSubmit(values)
    }
    next(values)
  }

  const activePage = Children.toArray(children)[state.page]
  const isLastPage = state.page === Children.count(children) - 1

  useEffect(() => {
    setPage(state.page + 1)
  }, [state.page])

  return (
    <Form initialValues={state.values} validate={validate} onSubmit={handleSubmit} subscription={{ submitting: true }}>
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          {activePage}
          <section className="flex justify-end gap-10">
            {state.page > 0 && (
              <Button variant="text" className="!max-w-fit" onClick={previous}>
                Atrás
              </Button>
            )}
            <SaveButton
              label={isLastPage ? 'Enviar' : 'Siguiente'}
              className="max-w-40 !font-bold"
              disabled={submitting}
            />
          </section>
        </form>
      )}
    </Form>
  )
}

export default Wizard

Wizard.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  children: PropTypes.array.isRequired,
  setPage: PropTypes.func,
  setIsLastStep: PropTypes.func
}
