import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Container } from '../../components/Container'
import FeedbackDrawer from '../../components/feedback/FeedbackDrawer'
import { MessageContent } from '../../components/notifications/MessageContent'
import { MessageNavigation } from '../../components/notifications/MessageNavigation'
import Breadcrumb from '../../components/ui/Breadcrumb'
import { MAX_LENGTH_MESSAGE_BREADCRUMB } from '../../constants/globals'
import { convertDataToBreadcrumbRoute, truncateText } from '../../helpers/helpers'
import { useMessageData, usePatchMessage } from '../../hooks/useMessages'

export const MessageDetailPage = () => {
  let { noti_id: notificationId } = useParams()
  const { isLoading, data: message } = useMessageData(notificationId, '/messages-user')
  const { mutate: patchMessage } = usePatchMessage()

  useEffect(() => {
    if (message && !message.is_read) patchMessage({ id: notificationId, state: ['read'], event: 'open' })
  }, [message])

  if (!message) return

  const breadcrumbData = convertDataToBreadcrumbRoute(truncateText(message.subject, MAX_LENGTH_MESSAGE_BREADCRUMB))

  return (
    <>
      <Breadcrumb routesKey="myNotifications" data={breadcrumbData} />
      <MessageNavigation message={message} />
      <Container>
        {isLoading && <div className="flex justify-center font-bold m-8 md:m-10">Cargando...</div>}
        {!isLoading && <MessageContent message={message} />}
        <FeedbackDrawer buttonLabel="Cuéntanos tu experiencia" />
      </Container>
    </>
  )
}
