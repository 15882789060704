import React from 'react'

import PropTypes from 'prop-types'

const MessageTableDataCell = ({ content, className, onDataCellClick }) => (
  <td className={className} onClick={onDataCellClick}>
    {content}
  </td>
)

const MessageTableRow = ({ rowData, onDataCellClick }) => (
  <>
    <tr>
      {Object.entries(rowData).map(([key, value], index) => (
        <MessageTableDataCell
          key={index}
          content={value}
          onDataCellClick={key === 'constancy' ? null : onDataCellClick}
        />
      ))}
    </tr>
    <tr className="h-2 !bg-white !p-0"></tr>
  </>
)

MessageTableRow.propTypes = {
  rowData: PropTypes.object,
  onDataCellClick: PropTypes.func
}

MessageTableDataCell.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  onDataCellClick: PropTypes.func
}

export default MessageTableRow
