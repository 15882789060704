import { request } from '../api/apiCore'
import { useLocationSearch } from './useLocationSearch'

export const useSunKey = () => {
  const search = useLocationSearch()

  const getSunatUserData = async (code, state, document_number) => {
    try {
      const userData = await request({
        url: '/sunkey/user.json',
        data: { code, state, document_number },
        method: 'POST'
      })
      const errorMessage = userData.response?.data?.error

      if (errorMessage) return { hasError: true, message: errorMessage }

      return { hasError: false, data: userData }
    } catch (error) {
      return { hasError: true, message: error }
    }
  }

  return { search, getSunatUserData }
}
