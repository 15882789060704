import React, { Fragment, useState } from 'react'

import PropTypes from 'prop-types'

import CloseIcon from '../../assets/images/close_blue_icon.svg'
import Wizard from '../ui/Wizard'
import steps, { Final } from './FeedbackSteps'

const FeedbackWizard = ({ onDrawerClose, onSubmit }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [isFinalStep, setIsFinalStep] = useState(false)

  return (
    <section className="pt-7.5 px-9 pb-9 bg-white rounded-l-lg shadow-admin-dashboard">
      <article className="flex">
        {!isFinalStep && <p className="font-medium">{`${currentPage}/${steps.length}`}</p>}
        <button className="flex font-bold text-blue-700 ml-auto" onClick={onDrawerClose}>
          <img src={CloseIcon} className="pr-2" alt="close-icon" />
          Cerrar
        </button>
      </article>
      <article className="pt-5">
        {!isFinalStep ? (
          <Wizard
            initialValues={{
              sms_received: 'yes',
              email_received: 'yes',
              can_download_attachments: 'true',
              description: '',
              contact_the_sender: false
            }}
            onSubmit={onSubmit}
            setPage={setCurrentPage}
            setIsLastStep={setIsFinalStep}
          >
            {steps.map((step, index) => (
              <Fragment key={index}>{step}</Fragment>
            ))}
          </Wizard>
        ) : (
          <Final />
        )}
      </article>
    </section>
  )
}

export default FeedbackWizard

FeedbackWizard.propTypes = {
  onDrawerClose: PropTypes.func,
  onSubmit: PropTypes.func
}
