import React from 'react'
import { Form } from 'react-final-form'

import PropTypes from 'prop-types'

import { Button } from '../fields/Button'
import RadioField from '../fields/RadioField'
import SaveButton from '../fields/SaveButton'
import TextField from '../fields/TextField'
import UbigeoCitizenField from '../fields/UbigeoCitizenField'
import * as validations from '../fields/Validations'
import HeadingTag from '../ui/HeadingTag'

const maritalStatusOptions = [
  { label: 'Soltero (a)', value: 'SOLTERO' },
  { label: 'Casado (a)', value: 'CASADO' },
  { label: 'Viudo (a)', value: 'VIUDO' },
  { label: 'Divorciado (a)', value: 'DIVORCIADO' }
]

const IdentityValidateForm = ({
  title,
  subtitle,
  subtitleHighlight,
  subtitleHighlightClassName,
  handleSubmitForm,
  handleCancelButton,
  cancelButtonLabel = 'Regresar'
}) => {
  return (
    <div className="p-6 mx-auto border border-blue-200 max-w-max md:p-9 shadow-auth-register rounded">
      <HeadingTag title={title} className="text-3xl font-bold md:text-center" />
      <p className="pt-5 pb-11 md:pb-7.5">
        {subtitle}
        <span className={subtitleHighlightClassName}>{subtitleHighlight}</span>
      </p>
      <Form
        initialValues={{ marital_status: 'single' }}
        onSubmit={handleSubmitForm}
        subscription={{ pristine: true, submitting: true, submitError: true }}
        render={({ handleSubmit, submitError, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              name="names"
              label="Nombre(s)"
              size="full"
              labelClassName="mb-2"
              validate={validations.required()}
            />
            <TextField
              name="first_surname"
              label="Primer apellido"
              size="full"
              labelClassName="mb-2"
              validate={validations.required()}
            />
            <TextField
              name="second_surname"
              label="Segundo apellido"
              size="full"
              labelClassName="mb-2"
              validate={validations.required()}
            />
            <RadioField
              name="marital_status"
              label="Estado civil"
              options={maritalStatusOptions}
              validate={validations.required()}
            />
            <UbigeoCitizenField
              name="ubigeo"
              payload={{ label: 'Departamento, provincia y distrito de tu domicilio' }}
              required
            />
            {submitError && <div className="pb-6 font-bold text-center text-red-500">{submitError}</div>}
            <div className="pb-3.75">
              <SaveButton label="Continuar" size="full" disabled={pristine || submitting} />
            </div>
            <Button size="full" variant="text" onClick={handleCancelButton}>
              {cancelButtonLabel}
            </Button>
          </form>
        )}
      />
    </div>
  )
}

IdentityValidateForm.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  subtitleHighlight: PropTypes.string,
  subtitleHighlightClassName: PropTypes.string,
  handleSubmitForm: PropTypes.func,
  handleCancelButton: PropTypes.func,
  cancelButtonLabel: PropTypes.string
}

export default IdentityValidateForm
