import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import PropTypes from 'prop-types'

import { request } from '../../api/apiCore'
import { useAuth } from '../../auth/Auth'
import { Container } from '../../components/Container'
import DocumentNumberForm from '../../components/forms/DocumentNumberForm'
import Loader from '../../components/ui/Loader'
import PopUpWindow from '../../components/ui/PopUpWindow'
import ScrollToTop from '../../components/ui/ScrollToTop'
import PasswordRecoveryOptions from '../../components/users/PasswordRecoveryOptions'
import { redirectTo } from '../../helpers/services'
import useShowMessage from '../../hooks/useShowMessage'
import { useSunKey } from '../../hooks/useSunKey'

const sendCode = async data => await request({ url: '/authentication/send-code.json', data, method: 'POST' })

const Content = ({ text }) => <span className="pt-6 pb-7.5">{text}</span>

export const PasswordRecoveryPage = () => {
  const { user, dispatch } = useAuth()
  const [showOptions, setShowOptions] = useState({ show: false, loader: false })
  const [userInfo, setUserInfo] = useState({})
  const { message, setMessage } = useShowMessage()
  const { search, getSunatUserData } = useSunKey()
  const navigate = useNavigate()

  useEffect(() => {
    validateSunKeyResponse()
  }, [search])

  const validateSunKeyResponse = async () => {
    if (search.isSearching) return

    if (search.code) {
      setShowOptions({ ...showOptions, loader: true })
      const response = await getSunatUserData(search.code, search.state, user.document_number)

      if (response.hasError) {
        setMessage({ title: 'Aviso', content: <Content text={response.message} />, show: true })
        setShowOptions({ ...showOptions, loader: false })
        navigate('/recuperar-contrasena', { replace: true })
      } else {
        dispatch({ type: 'Recovery', payload: { token: response.data.token } })
        navigate('/contrasena-usuario', { replace: true })
      }
    }
  }

  const handleSubmitDocument = async ({ document_number }) => {
    try {
      const document_type_code = 'dni'
      const data = { document_type_code, document_number }
      const verifyDocument = await request({ url: '/authentication/verify-document.json', method: 'POST', data })

      if (verifyDocument.user) {
        setShowOptions({ ...showOptions, show: true })
        setUserInfo(verifyDocument.user)
      } else {
        setMessage({ title: 'Aviso', content: <Content text={verifyDocument.response.data.error} />, show: true })
      }
    } catch (error) {
      setMessage({ title: 'Error', content: <Content text={error.response.data.message} />, show: true })
    }
  }

  const handleSubmitEmail = async () => {
    try {
      const response = await sendCode({ ...userInfo })

      if (response.salt) {
        dispatch({ type: 'Recovery', payload: { ...response, ...userInfo } })
        navigate('/verificar-codigo')
      } else {
        setMessage({ title: 'Aviso', content: <Content text="No se pudo enviar código" />, show: true })
      }
    } catch (error) {
      setMessage({ title: 'Error', content: <Content text={error.response.data.message} />, show: true })
    }
  }

  const handleSubmitMovil = async () => {
    try {
      const data = { ...userInfo, send_type: 'sms' }
      const response = await sendCode({ ...userInfo, send_type: 'sms' })

      if (response.salt) {
        dispatch({ type: 'Recovery', payload: { ...response, ...data } })
        navigate('/verificar-codigo')
      } else {
        setMessage({ title: 'Aviso', content: <Content text="No se pudo enviar código" />, show: true })
      }
    } catch (error) {
      setMessage({ title: 'Error', content: <Content text={error.response.data.message} />, show: true })
    }
  }

  const handleRecoveryOption = option => {
    dispatch({ type: 'Recovery', payload: { ...userInfo } })

    if (option === 'sol') redirectTo('SUNKEYREC')
    if (option === 'pide') navigate('/recuperar-contrasena-pide')
    if (option === 'facial') redirectTo('IDPERUREC')
  }

  return (
    <Container className="px-5 pt-8 md:h-screen pb-14 md:px-20 md:pt-14 md:pb-18">
      {!showOptions.show && !showOptions.loader && (
        <DocumentNumberForm
          title="Recuperar contraseña"
          titleClassName="pb-6 md:pb-7.5 md:text-center"
          textLabel="Ingresa tu número de DNI"
          handleSubmitForm={handleSubmitDocument}
          handleCancelButton={() => navigate('/')}
        />
      )}
      {showOptions.loader && <Loader message="Espera un momento, estamos validando los datos ingresados." />}
      {message.show && (
        <PopUpWindow
          title={message.title}
          paperProps={{
            padding: 36,
            borderRadius: 0,
            maxWidth: 482,
            boxShadow: '2px 3px 23px -4px #D3E0EC'
          }}
          showCloseButton
          open={message.show}
          onClose={() => setMessage({ ...message, show: false })}
        >
          {message.content}
        </PopUpWindow>
      )}
      {showOptions.show && (
        <PasswordRecoveryOptions
          handleEmailOptionButton={handleSubmitEmail}
          handleMovilOptionButton={handleSubmitMovil}
          handleSolOptionButton={() => handleRecoveryOption('sol')}
          handlePideOptionButton={() => handleRecoveryOption('pide')}
          handleSelfieOptionButton={() => handleRecoveryOption('facial')}
        />
      )}
      <ScrollToTop />
    </Container>
  )
}

Content.propTypes = {
  text: PropTypes.string
}
